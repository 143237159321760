import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Alert, InputAdornment, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '@/hooks/useAuth';
import { FormProvider, RHFTextField } from '@/components/atom/hook-form';
import usePermission from '@/hooks/usePermission';
import { permissionEnum } from '@/enum/permission';
import { errorType } from '@/enum/error';
import { validateSuspendedAccount } from '@/services/API/user';
import { PATH_AUTH, PATH_EXTERNAL } from '@/routes/paths';
import { sessionStorageEnum } from '@/enum/sessionStorage';

export default function LoginForm() {
  const { login, logout } = useAuth();
  const { fetchPermission } = usePermission();

  const [searchParams] = useSearchParams();

  const error = searchParams.get('error');

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address.'),
    password: Yup.string().required('Please enter your password.'),
    remember: Yup.bool(),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const watchEmail = watch('email');
  const watchPassword = watch('password');

  const onSubmit = async (data) => {
    try {
      await validateSuspendedAccount(data.email);

      await sessionStorage.removeItem(sessionStorageEnum.activeWorkspaceId);
      await login(data.email, data.password);

      const permission = await fetchPermission();

      const isValid = permission.find((permission) => permission === permissionEnum.BO_VIEW);

      if (!isValid) {
        // eslint-disable-next-line no-throw-literal
        throw {
          response: {
            data: {
              errorMessage: 'You don’t have access to My Back Office.',
            },
          },
        };
      }

      reset();

      navigate('/dashboard');
    } catch (error) {
      if (error.response.status === 404) {
        setError('afterSubmit', { ...error, message: 'Incorrect password, please try again.' });
      } else if (error.response.status === 400) {
        setError('afterSubmit', { ...error, message: 'Something wrong with your account. Please contact Admin.' });
      } else {
        await logout();
        const errorMessage = error.response?.data?.errorMessage;

        setError('afterSubmit', { ...error, message: errorMessage ?? 'Incorrect password, please try again.' });
      }
    }
  };

  useEffect(() => {
    if (error === errorType.accountSuspended) {
      setError('afterSubmit', { ...error, message: 'Something wrong with your account. Please contact Admin.' });
    }
  }, [error, setError]);

  useEffect(() => {
    if (watchEmail !== '' || watchPassword !== '') {
      clearErrors('afterSubmit');
      navigate(PATH_AUTH.login, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEmail, watchPassword]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField name="email" label="Email address" />
        <RHFTextField
          name="password"
          placeholder="Password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button size="medium" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Box mt={1}>
        <Button size="small" variant="text" href={PATH_EXTERNAL.storefront.forgotPassword} target="_blank">
          Forget password?
        </Button>
      </Box>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ mt: 2 }}>
        Log In
      </LoadingButton>
    </FormProvider>
  );
}
