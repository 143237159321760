import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(duration);

// set default locale timezone
const timeZone = dayjs.tz.guess();
dayjs.tz.setDefault(timeZone);

/**
 * Return the formatted of short month and year (ex. Jun 2020)
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @param {string} defaultValue
 * @returns {string} Month and Year (ex. Jun 2020)
 */
export const toMonthYear = (dateString = '', defaultValue = '') =>
  dateString ? dayjs(dateString).format('MMM YYYY') : defaultValue;

/**
 * Return the formatted of day of the week with month and date
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Day with month and year (ex. Mon, Nov 15)
 */
export const toDayMonthDate = (dateString = '') => dayjs(dateString).format('ddd, MMM D');

/**
 * Return the formatted of day of the week with month, date, and year
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Day with month and year (ex. Mon, Nov 15, 2021)
 */
export const toDayMonthDateYear = (dateString = '') => dayjs(dateString).format('ddd, MMM DD, YYYY');

/**
 * Return the formatted of day of the week with month, date, year and time
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Day with month, date, year and time (ex. Mon, Nov 15, 2021 at 00:00)
 */
export const toDayMonthDateYearTime = (dateString) => dayjs(dateString).format('ddd, MMM D, YYYY, HH:mm');

/**
 * Return the formatted of day of the week with month, date, year and time
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Day with month, date, year and time (ex. Mon, Nov 15, 2021 at 00:00)
 */
export const toDayMonthDateYearTimeSec = (dateString) => dayjs(dateString).format('ddd, MMM D, YYYY, HH:mm:ss');
/**
 * Return the formatted of month, date, year and time
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Month, date, year and time (ex. Nov 15, 2021 at 00:00)
 */
export const toMonthDateYearTime = (dateString = '') => dayjs(dateString).format('MMM D, YYYY [at] HH:mm');

/**
 * Return the formatted of time
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Time (ex. 00:00)
 */
export const toOnlyTime = (dateString = '') => dayjs(dateString).format('HH:mm');

/**
 * Return the formatted of month, date, and year
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} Day with month and year (ex. Nov 15, 2021)
 */
export const toMonthDateYear = (dateString = '') => dayjs(dateString).format('MMM DD, YYYY');

/**
 * Return the formatted of 12-hour or 24-hour clock time
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @param {integer} hourClock [12, 24]
 * @returns {string} Clock time (ex. 12:00 am)
 */
export const toClockHourTime = (dateString = '', hourClock = 24) => {
  const hour = hourClock === 24 ? 'HH' : 'hh';
  const ampm = hourClock === 24 ? '' : ' a';

  return dayjs(dateString).format(`${hour}:mm${ampm}`);
};

/**
 * Return the offset from UTC
 * @param {string} dateString (ex. '2018-03-29T17:00:00Z')
 * @returns {string} The offset from UTC, (ex. 'GTM+7')
 */
export const getOffsetFromUTC = (dateString = undefined) => dayjs(dateString).utc().local().format('z');

export const getDateStartFromDay = (date) => dayjs(date).startOf('day').toJSON();

export const getDateUTC = (date) => dayjs(date).utc();

export const getDateLocal = (date) => dayjs(date);

export const groupDateAvailableUTCtoLocal = (utc = []) =>
  utc.reduce((acc, next) => {
    const key = dayjs(next.startDate).startOf('d').format();
    const payload = {
      id: next.id,
      startDate: getDateLocal(next.startDate).format(),
      endDate: getDateLocal(next.endDate).format(),
      duration: next.duration,
      ...(next.status ? { status: next.status } : {}),
    };

    if (acc[key]) {
      return {
        ...acc,
        [key]: [...acc[key], payload],
      };
    }

    return {
      ...acc,
      [key]: [payload],
    };
  }, {});

export const getMilliSecLeft = ({ start = dayjs(), end = dayjs().add(1, 'h'), left = 1, unit = 'm' }) =>
  dayjs(end).subtract(left, unit).diff(start, 'ms');

export const getSubtract = (date, value, unit) => dayjs(date).subtract(value, unit);

/**
 * Return short day of week
 * @param {number} day number of day (ex. 0)
 * @returns {string} Short day of week (ex. 'sun')
 */
export const dayToString = (day) => ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'][day];

/**
 * Return label day of week
 * @param {number} day number of day (ex. 0)
 * @returns {string} Short day of week (ex. 'Sunday')
 */
export const dayToLabel = (day) => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];

/**
 * Return the formatted of 24-hour clock time
 * @param {number} number number of time (ex. 1, 1.5, 2, 23.5)
 * @returns {string} 24-hour clock time (ex. 01:00, 01:30, 02:00, 23:30)
 */
export const numberToTimeString = (number) => {
  const minute = (Math.abs(number) - Math.floor(number)) * 60;
  return dayjs().hour(parseInt(number, 10)).minute(minute).format('HH:mm');
};

/**
 * Return convert 24-hour clock time to number
 * @param {string} timeString 24-hour clock time (ex. 01:00, 01:30, 02:00, 23:30)
 * @returns {number} number of time (ex. 1, 1.5, 2, 23.5)
 */
export const timeStringToNumber = (timeString = '') => {
  const [hour, minute] = timeString.split(':');
  return Number(hour) + Number(minute / 60);
};

export const getMinutesDuration = (start, end) => dayjs.duration(end.diff(start)).asMinutes();

export const getEngagementTimeToString = (minutes = '') => {
  const hours = Math.floor(
    dayjs
      .duration({
        minutes,
      })
      .asHours()
  );

  const minute = dayjs
    .duration({
      minutes: minutes - hours * 60,
    })
    .minutes();
  return `${hours}h ${minute}m`;
};
export default dayjs;
