import { workspaceStatus } from '@/enum/workspaceStatus';
import useAuth from '@/hooks/useAuth';
import useResponsive from '@/hooks/useResponsive';
import useWorkspace from '@/hooks/useWorkspace';
import { toDayMonthDateYear } from '@/utils/date';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';

const { active } = workspaceStatus;

const initialState = {
  isNearlyInactive: false,
  isExpired: false,
  showAlert: true,
};

const AlertBarContext = createContext(initialState);

AlertBarProvider.propTypes = {
  children: PropTypes.node,
};

function AlertBarProvider({ children }) {
  const { user, isAuthenticated } = useAuth();
  const { companyId } = user;

  const location = useLocation();

  const regex = /auth/;
  const isAuthPage = regex.test(location.pathname);

  const [height, setHeight] = useState(0);

  const ref = useRef(null);

  const isMobile = useResponsive('down', 'sm');

  const defaultHeight = isMobile ? 62 : 38;

  const { fetchWorkspace, inactiveAt, status } = useWorkspace();

  const [openAlert, setOpenAlert] = useState(true);
  const [expiredDate, setExpiredDate] = useState();

  const onClose = () => {
    setOpenAlert(false);
    sessionStorage.setItem('close-alert', 'true');
  };

  const fetchWorkspaceStatus = useCallback(async () => {
    const { inactiveAt, status } = await fetchWorkspace(companyId);

    if (status !== active) {
      setOpenAlert(true);

      setExpiredDate(toDayMonthDateYear(inactiveAt));
    }
  }, [companyId, fetchWorkspace]);

  useEffect(() => {
    const state = sessionStorage.getItem('close-alert');

    if (state !== 'true' && companyId) {
      fetchWorkspaceStatus();
    } else {
      setOpenAlert(false);
    }
  }, [fetchWorkspaceStatus, companyId]);

  const setCurrentHeight = useMemo(() => {
    return debounce(() => {
      const showAlert = isAuthenticated && status !== active;

      setHeight(ref.current?.clientHeight || (showAlert ? defaultHeight : 0));
    }, 30); // set millisecond debounce for performance

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isAuthenticated]);

  useLayoutEffect(() => {
    window.addEventListener('resize', setCurrentHeight);
    setCurrentHeight();

    return () => window.removeEventListener('resize', setCurrentHeight);
  }, [ref, setCurrentHeight]);

  const showAlert = isAuthenticated && status !== active && openAlert && !isAuthPage;

  return (
    <AlertBarContext.Provider
      value={{
        inactiveAt,
        status,
        showAlert,
        expiredDate,
        onClose,
        openAlert,
        height,
        ref,
      }}
    >
      {children}
    </AlertBarContext.Provider>
  );
}

export { AlertBarProvider, AlertBarContext };
