/**
 * Sort array of object by key
 * @param {Array} data
 * @param {Object} options
 * @param {String} [options.sortFrom='label']
 * @param {'string'|'number'|'date'} [options.propType='string']
 * @param {'asc'|'desc'} [options.sortBy='asc']
 * @return {Array} Sorted array
 */
export const sortWith =
  (options = {}) =>
  (data = []) => {
    const { sortFrom, propType, sortBy } = {
      sortFrom: 'label',
      sortBy: 'asc',
      propType: 'string',
      ...options,
    };

    return data.sort((a, b) => {
      let nameA = a[sortFrom];
      let nameB = b[sortFrom];

      if (propType === 'string') {
        nameA = nameA.toUpperCase();
        nameB = nameB.toUpperCase();
      } else if (propType === 'number') {
        nameA = Number(nameA);
        nameB = Number(nameB);
      } else if (propType === 'date') {
        nameA = new Date(nameA).getTime();
        nameB = new Date(nameB).getTime();
      }

      let valueSortBy = 0;

      if (sortBy.toUpperCase() === 'ASC') {
        valueSortBy = 1;
      } else if (sortBy.toUpperCase() === 'DESC') {
        valueSortBy = -1;
      }

      if (nameA < nameB) {
        return -1 * valueSortBy;
      }
      if (nameA > nameB) {
        return 1 * valueSortBy;
      }
      return 0;
    });
  };
