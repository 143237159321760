import {
  Avatar,
  Box,
  ListSubheader as MuiListSubheader,
  MenuItem as MuiMenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes, { number, string } from 'prop-types';
import SvgIconStyle from '@/components/atom/SvgIconStyle';

import { useMemo } from 'react';

const CheckIcon = () => (
  <SvgIconStyle src={`/assets/icons/ic_check.svg`} sx={{ width: 16, height: 16, minWidth: 16 }} />
);

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  fontSize: theme.mixins.rem(16),
  color: theme.palette.action.active,
}));

const ListSubheader = styled(MuiListSubheader)(({ theme }) => ({
  lineHeight: 1,
  marginBottom: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => {
  return {
    whiteSpace: 'unset',
    padding: theme.spacing(1),

    '&.Mui-selected': {
      background: theme.palette.action.selected,

      '&:hover': {
        background: theme.palette.action.selected,
      },
      '&:focus': {
        background: theme.palette.action.selected,
      },
    },
    '.MuiTypography-root': {
      '&.MuiTypography-subtitle2': {
        fontWeight: 700,
        paddingRight: theme.spacing(3),
      },
      '&.MuiTypography-caption': {
        fontWeight: 400,
      },
    },
  };
});

const MultipleWorkspaceSelect = ({ onChangeWorkspace = () => {}, value, workspaceList = [] }) => {
  const hashMapWorkSpace = useMemo(
    () =>
      workspaceList.reduce((acc, workspace) => {
        return {
          ...acc,
          [workspace.id]: {
            ...workspace,
          },
        };
      }, {}),
    [workspaceList]
  );

  const handleChangeWorkSpace = (evt) => {
    const { value } = evt.target;
    const selectItem = hashMapWorkSpace[value];
    onChangeWorkspace(selectItem);
  };

  const workspaceSelected = useMemo(() => {
    const selected = hashMapWorkSpace[value];

    if (!selected) {
      const defaultValue = workspaceList.length > 0 ? workspaceList[0] : '';

      return defaultValue;
    }

    return selected;
  }, [hashMapWorkSpace, value, workspaceList]);
  const disabled = workspaceList.length === 0;

  return (
    <Box mr={{ sm: 2 }}>
      <Select
        onChange={handleChangeWorkSpace}
        value={value || workspaceSelected?.id}
        disabled={disabled}
        size="small"
        fullWidth
        MenuProps={{
          sx: {
            width: {
              xs: 300,
              sm: 340,
            },
            '.MuiPaper-root': {
              width: {
                xs: 300,
                sm: 340,
              },
            },
            '.MuiMenu-list': {
              padding: '8px',
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        sx={{
          '.MuiSelect-select': {
            p: 1,
            background: 'white',
          },
        }}
        renderValue={({ ...props }) => {
          return (
            <Box {...props}>
              <Stack direction="row" gap={1}>
                {!disabled && (
                  <Avatar
                    sx={{
                      borderRadius: 0.5,
                      width: 26,
                      height: 26,
                    }}
                    variant="square"
                    alt={workspaceSelected?.name}
                    src={workspaceSelected?.logoUrl}
                  >
                    {workspaceSelected?.name[0]}
                  </Avatar>
                )}
                <Stack justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    fontWeight={700}
                    textOverflow="ellipsis"
                    noWrap
                    width={{ xs: 130 }}
                    title={workspaceSelected?.name}
                  >
                    {workspaceSelected?.name}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          );
        }}
      >
        <ListSubheader>
          <Typography variant="caption" color="text.secondary">
            My Back Office
          </Typography>
        </ListSubheader>
        {workspaceList.map((workspace) => {
          const { name, id, logoUrl, member } = workspace;
          return (
            <MenuItem key={id} value={id}>
              {id === value ? (
                <Box display="flex" p={1} sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <StyledCheckIcon />
                </Box>
              ) : null}
              <Stack direction="row" gap={1}>
                <Avatar
                  sx={{
                    borderRadius: 0.5,
                    width: 36,
                    height: 36,
                  }}
                  variant="square"
                  width={36}
                  height={36}
                  alt={name}
                  src={logoUrl}
                >
                  {name[0]}
                </Avatar>
                <Stack>
                  <Typography variant="subtitle2" fontWeight={700} title={name}>
                    {name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {member} members
                  </Typography>
                </Stack>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

MultipleWorkspaceSelect.propTypes = {
  onChangeWorkspace: PropTypes.func,
  value: PropTypes.oneOfType([number, string]),
  workspaceList: PropTypes.array,
  // propName: PropTypes.string,
};

export default MultipleWorkspaceSelect;
