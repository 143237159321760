import HelperText from '@/components/atom/hook-form/HelperText';
// @mui
import { Box, FormHelperText, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  inputProps: PropTypes.object,
  maxLength: PropTypes.number,
};

export default function RHFTextField({ name, rows, multiline, inputProps, maxLength, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <TextField
            {...field}
            fullWidth
            rows={rows}
            inputProps={{
              maxLength,
              ...inputProps,
            }}
            multiline={multiline}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            {...other}
          />
          {!other.disabled && error && (
            <FormHelperText error component="div">
              <HelperText text={error.message} />
            </FormHelperText>
          )}
          {!error && (
            <FormHelperText
              sx={{
                textAlign: 'right',
              }}
            >
              {field.value?.length || 0}/{maxLength}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
}
