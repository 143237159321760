import { AlertBarContext } from '@/contexts/AlertBarContext';
import { useContext } from 'react';

const useAlertBar = () => {
  const context = useContext(AlertBarContext);

  if (!context) throw new Error('Alert bar context must be use inside AlertBarProvider');

  return context;
};

export default useAlertBar;
