import HelperText from '@/components/atom/hook-form/HelperText';
// @mui
import { Box, FormHelperText, styled, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
// form
import { Controller, useFormContext } from 'react-hook-form';
// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  SelectProps: PropTypes.object,
};
const WrapperTextField = styled(Box)(({ theme }) => {
  return {
    '.MuiSvgIcon-root': {
      right: theme.spacing(1),
    },
  };
});
export default function RHFSelect({ name, children, SelectProps, ...other }) {
  const { control } = useFormContext();
  const wrapperRef = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  useEffect(() => {
    if (wrapperRef) {
      const { clientWidth } = wrapperRef.current;
      setWrapperWidth(clientWidth);
    }
  }, [wrapperRef]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <WrapperTextField ref={wrapperRef}>
          <TextField
            {...field}
            select
            fullWidth
            SelectProps={{
              native: true,
              MenuProps: {
                MenuListProps: {
                  sx: {
                    padding: 1,
                  },
                },
                PaperProps: {
                  sx: {
                    width: wrapperWidth,
                  },
                },
              },

              ...SelectProps,
            }}
            error={!!error}
            {...other}
          >
            {children}
          </TextField>
          {!other.disabled && error && (
            <FormHelperText error component="div">
              <HelperText text={error.message} />
            </FormHelperText>
          )}
        </WrapperTextField>
      )}
    />
  );
}
