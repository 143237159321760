export const permissionEnum = Object.freeze({
  SF_VIEW: 'SF_VIEW',
  BO_VIEW: 'BO_VIEW',

  BO_SESSION_VIEW: 'BO_SESSION_VIEW',

  // NOTE: user cannot login in BO
  BO_USER_ONBOARD_VIEW: 'BO_USER_ONBOARD_VIEW',
  BO_USER_ONBOARD_ADD: 'BO_USER_ONBOARD_ADD',
  BO_USER_ONBOARD_EDIT: 'BO_USER_ONBOARD_EDIT',
  BO_USER_ONBOARD_DELETE: 'BO_USER_ONBOARD_DELETE',

  BO_USER_INVITE_ADD: 'BO_USER_INVITE_ADD',
  BO_USER_INVITE_VIEW: 'BO_USER_INVITE_VIEW',

  BO_DOMAIN_EXPERTISE_ADD: 'BO_DOMAIN_EXPERTISE_ADD',
  BO_DOMAIN_EXPERTISE_VIEW: 'BO_DOMAIN_EXPERTISE_VIEW',
  BO_DOMAIN_EXPERTISE_EDIT: 'BO_DOMAIN_EXPERTISE_EDIT',
  BO_DOMAIN_EXPERTISE_DELETE: 'BO_DOMAIN_EXPERTISE_DELETE',

  BO_EXPERTISE_ADD: 'BO_EXPERTISE_ADD',
  BO_EXPERTISE_VIEW: 'BO_EXPERTISE_VIEW',
  BO_EXPERTISE_EDIT: 'BO_EXPERTISE_EDIT',
  BO_EXPERTISE_DELETE: 'BO_EXPERTISE_DELETE',

  BO_BUSINESS_UNIT_ADD: 'BO_BUSINESS_UNIT_ADD',
  BO_BUSINESS_UNIT_VIEW: 'BO_BUSINESS_UNIT_VIEW',
  BO_BUSINESS_UNIT_EDIT: 'BO_BUSINESS_UNIT_EDIT',
  BO_BUSINESS_UNIT_DELETE: 'BO_BUSINESS_UNIT_DELETE',

  BO_DEPARTMENT_ADD: 'BO_DEPARTMENT_ADD',
  BO_DEPARTMENT_VIEW: 'BO_DEPARTMENT_VIEW',
  BO_DEPARTMENT_EDIT: 'BO_DEPARTMENT_EDIT',
  BO_DEPARTMENT_DELETE: 'BO_DEPARTMENT_DELETE',

  BO_TEAM_ADD: 'BO_TEAM_ADD',
  BO_TEAM_VIEW: 'BO_TEAM_VIEW',
  BO_TEAM_EDIT: 'BO_TEAM_EDIT',
  BO_TEAM_DELETE: 'BO_TEAM_DELETE',

  BO_JOB_LEVEL_ADD: 'BO_JOB_LEVEL_ADD',
  BO_JOB_LEVEL_VIEW: 'BO_JOB_LEVEL_VIEW',
  BO_JOB_LEVEL_EDIT: 'BO_JOB_LEVEL_EDIT',
  BO_JOB_LEVEL_DELETE: 'BO_JOB_LEVEL_DELETE',

  BO_ROLE_SETTING_VIEW: 'BO_ROLE_SETTING_VIEW',

  BO_ROLE_SETTING_ADD_SUPER_ADMIN: 'BO_ROLE_SETTING_ADD_SUPER_ADMIN',
  BO_ROLE_SETTING_ADD_ADMIN: 'BO_ROLE_SETTING_ADD_ADMIN',
  BO_ROLE_SETTING_ADD_ANALYST: 'BO_ROLE_SETTING_ADD_ANALYST',

  BO_ROLE_SETTING_EDIT_SUPER_ADMIN: 'BO_ROLE_SETTING_EDIT_SUPER_ADMIN',
  BO_ROLE_SETTING_EDIT_ADMIN: 'BO_ROLE_SETTING_EDIT_ADMIN',
  BO_ROLE_SETTING_EDIT_ANALYST: 'BO_ROLE_SETTING_EDIT_ANALYST',

  BO_ROLE_SETTING_DELETE_SUPER_ADMIN: 'BO_ROLE_SETTING_DELETE_SUPER_ADMIN',
  BO_ROLE_SETTING_DELETE_ADMIN: 'BO_ROLE_SETTING_DELETE_ADMIN',
  BO_ROLE_SETTING_DELETE_ANALYST: 'BO_ROLE_SETTING_DELETE_ANALYST',

  // NOTE: To future
  BO_USER_SUSPEND: 'BO_USER_SUSPEND',
  BO_USER_DELETE: 'BO_USER_DELETE',

  BO_GUEST_REQUEST_VIEW: 'BO_GUEST_REQUEST_VIEW',
});
