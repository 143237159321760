import { onCloseModalPermission } from '@/redux/slices/permission';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogTitle, styled, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import useAuth from '@/hooks/useAuth';
import { useNavigate } from 'react-router';
import { PATH_AUTH } from '@/routes/paths';

const DialogWrapper = styled(Dialog)(() => ({
  '.MuiDialogActions-root': {
    paddingTop: '0 !important',
  },
}));

const DialogContent = styled(Box)(({ theme }) => ({ padding: theme.spacing(2, 3) }));

export const PermissionModal = () => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isOpen, title, message } = useSelector((state) => state.permission);

  const handleClose = async () => {
    dispatch(onCloseModalPermission());

    await logout();
    navigate(PATH_AUTH.login, { replace: true });
  };

  return (
    <DialogWrapper
      open={isOpen}
      fullWidth
      onClose={handleClose}
      transitionDuration={{
        appear: 200,
        exit: 0,
      }}
    >
      <Box display="flex" alignItems="center" width="100%">
        <DialogTitle display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="h6">{title}</Typography>
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
              padding: (theme) => theme.spacing(0.5),
            }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </DialogTitle>
      </Box>
      <DialogContent>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          {message}
        </Typography>
      </DialogContent>
      <Box px={3} pb={3} pt={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={handleClose} color="primary" sx={{ minWidth: '120px' }}>
          Log in
        </Button>
      </Box>
    </DialogWrapper>
  );
};
