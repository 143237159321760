// ----------------------------------------------------------------------

export default function MenuItem(theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: theme.mixins.rem(8),
          '&.Mui-selected': {
            backgroundColor: theme.palette.other.dropdownSelected,
          },
        },
      },
    },
  };
}
