import axios from 'axios';
import { AUTH } from '@/contexts/FirebaseContext';
import { store } from '@/redux/store';
import { onOpenModalPermission } from '@/redux/slices/permission';
import { onSuspendedAuthorized } from '@/redux/slices/authorized';
import { FORBIDDEN_CODE, permissionErrorCode, unauthorizedErrorCode, UNAUTHORIZED_CODE } from '@/enum/errorCode';
import { sessionStorageEnum } from '@/enum/sessionStorage';

const auth = {
  async getCurrentUser() {
    return AUTH.currentUser;
  },
};

const axiosDefaultConfig = {
  baseURL: process.env.REACT_APP_BACK_OFFICE_API_URL,
  withCredentials: true,
};

const instance = axios.create(axiosDefaultConfig);

// *Create new instance and use when need to fetch data on server side
// *Note: when use this instant must be add `axiosSSR.interceptors.request.use(config => onOverrideConfig(config, ctx))` before fetch data
export const axiosSSR = axios.create(axiosDefaultConfig);

export const axiosNext = axios.create({
  withCredentials: true,
  baseURL: '',
});

const onAxiosError = async (error) => {
  const status = error?.response?.status || '';
  const message = error?.response?.data?.errorMessage || '';
  const errorCode = error?.response?.data?.params?.errorCode;

  if (status && message) {
    if (status === 500) {
      console.error('Error API');
    }

    if (status === FORBIDDEN_CODE) {
      switch (errorCode) {
        case permissionErrorCode.permissionHasInvoke:
          store.dispatch(onOpenModalPermission('remove'));
          break;
        case permissionErrorCode.permissionHasDenied:
          store.dispatch(onOpenModalPermission('demote'));
          break;
        default:
          break;
      }

      return Promise.reject();
    }

    if (status === UNAUTHORIZED_CODE) {
      switch (errorCode) {
        case unauthorizedErrorCode.accountSuspended: {
          store.dispatch(onSuspendedAuthorized());
          break;
        }
        default:
          break;
      }
      return Promise.reject();
    }
  }

  return Promise.reject(error);
};

/**
 * Override axios config
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {import('axios').AxiosRequestConfig}
 */
export const onOverrideConfig = async (config, ctx) => {
  const queryParams = new URLSearchParams(process.browser ? window.location.search : ctx?.query ?? '');
  const newHeaderDict = {};
  queryParams.forEach((val, key) => {
    newHeaderDict[key] = encodeURIComponent(val);
  });
  const activeWorkspaceId = process.browser ? sessionStorage.getItem(sessionStorageEnum.activeWorkspaceId) : '';
  const isExistCompanyId =
    process.browser &&
    activeWorkspaceId &&
    activeWorkspaceId !== null &&
    activeWorkspaceId !== undefined &&
    activeWorkspaceId !== 'undefined';

  config.headers = {
    ...config.headers,
    ...config.params,
    ...newHeaderDict,
    Pathname: process.browser ? window.location.pathname : ctx?.req?.url ?? '',
    ...(isExistCompanyId
      ? {
          CompanyId: sessionStorage.getItem(sessionStorageEnum.activeWorkspaceId),
        }
      : {}),
  };

  const user = (await auth.getCurrentUser()) || ctx?.AuthUser;

  if (user) {
    const token = await user.getIdToken();
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${token}`;

    return newConfig;
  }

  return config;
};

instance.interceptors.request.use(onOverrideConfig);

instance.interceptors.response.use((config) => config, onAxiosError);

export default instance;
