import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import useAuth from '@/hooks/useAuth';
import { sessionStorageEnum } from '@/enum/sessionStorage';
import { getLastActiveWorkspace, getValidateWorkspace } from '@/services/API/workspace';

import { useLocation, useNavigate } from 'react-router-dom';
import useWorkspace from '@/hooks/useWorkspace';

WorkspaceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const WorkspaceContext = createContext({
  isWorkspaceFetching: false,
  companyId: undefined,
  workspaceName: undefined,
  changeWorkspace: () => {},
});

function WorkspaceProvider({ children }) {
  const [isWorkspaceLoading, setWorkspaceLoading] = useState(true);
  const [isWorkspaceValid, setWorkspaceValid] = useState(false);

  const { user, loadFullUserProfile, isFullProfile, logout } = useAuth();
  const { isLoading: isWorkspaceFetching, fetchWorkspace, name: workspaceName } = useWorkspace();
  const [companyId, setCompanyId] = useState('');
  const { search } = useLocation();
  const navigate = useNavigate();
  const mainUserId = useMemo(() => user?.mainUserId, [user]);
  const isValidProfile = useMemo(() => user?.profile, [user]);

  // find the active company id from query param, session storage, or api, respectively
  useEffect(() => {
    // NOTE: reset company id from state
    setCompanyId('');

    // wait until user is loaded
    if (isValidProfile) {
      const params = new URLSearchParams(search);
      const localCompanyId =
        params.get('companyId') || process.browser ? sessionStorage.getItem(sessionStorageEnum.activeWorkspaceId) : '';
      // once read the company id, remove it from params
      params.delete('companyId');
      window.history.replaceState(null, null, `${window.location.pathname}?${params}`);

      if (localCompanyId && localCompanyId !== 'undefined') {
        setCompanyId(localCompanyId);
      } else {
        getLastActiveWorkspace()
          .then((result) => {
            const { Data } = result.data;
            setCompanyId(Data.CompanyId);
            sessionStorage.setItem(sessionStorageEnum.activeWorkspaceId, Data.CompanyId);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }, [setCompanyId, search, isValidProfile]);

  // once get the company id, validate workspace permission with api
  useEffect(() => {
    if (companyId) {
      setWorkspaceLoading(true);
      getValidateWorkspace(companyId)
        .then((result) => {
          const { Data } = result.data;
          setWorkspaceValid(Data.IsValid);
          fetchWorkspace(companyId);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setWorkspaceLoading(false);
        });
    }
  }, [companyId, fetchWorkspace]);

  const changeWorkspace = useCallback(
    async (companyId) => {
      setWorkspaceLoading(true);
      setWorkspaceValid(false);
      setCompanyId(companyId);

      await loadFullUserProfile(mainUserId, companyId);

      // trigger useEffect below
    },
    [loadFullUserProfile, mainUserId]
  );

  // once workspace is loaded and validated, process the result
  useEffect(() => {
    if (!isWorkspaceLoading) {
      if (!isWorkspaceValid) {
        console.error('Error: Invalid workspace');
        setWorkspaceLoading(true);
        setWorkspaceValid(false);
        logout();
      } else if (companyId) {
        sessionStorage.setItem(sessionStorageEnum.activeWorkspaceId, companyId);
        if (!isFullProfile) {
          loadFullUserProfile(user?.mainUserId, companyId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorkspaceLoading, companyId, navigate, isWorkspaceValid, isFullProfile]);

  return (
    <WorkspaceContext.Provider
      value={{
        companyId,
        isWorkspaceFetching,
        workspaceName,
        changeWorkspace,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

export { WorkspaceContext, WorkspaceProvider };
