import axiosInstance from '@/services/API/axios';

export const getWorkspace = async (workspaceId = '') => {
  const workspaceResponse = await axiosInstance.get(`/company/${workspaceId}`);

  const { Name, Status, InactiveAt, ExpiredAt } = workspaceResponse.data;

  return { name: Name, status: Status, inactiveAt: InactiveAt, expiredAt: ExpiredAt };
};

export const fetchWorkspaceList = async () => {
  const workspaceListResponse = await axiosInstance.get('/workspace');
  return workspaceListResponse;
};

export const getLastActiveWorkspace = () => {
  return axiosInstance.get('/workspace/last-active');
};

export const getValidateWorkspace = (workspaceId) => {
  return axiosInstance.get(`/workspace/${workspaceId}/validate`);
};
