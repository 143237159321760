import { useContext } from 'react';
import { WorkspaceContext } from '@/contexts/WorkspaceContext';

const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext);

  if (!context) throw new Error('Workspace context must be use inside WorkspaceProvider');

  return context;
};

export default useWorkspaceContext;
