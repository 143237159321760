// @mui
import { Box, Link, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
//
import Breadcrumbs from '../atom/Breadcrumbs';
import TypographyEllipsis from '../atom/typography/TypographyEllipsis';

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  subHeader: PropTypes.string,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
  info: PropTypes.node,
  noTitle: PropTypes.bool,
};

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  subHeader,
  info,
  moreLink = '' || [],
  sx,
  noTitle,
  ...other
}) {
  return (
    <Box sx={{ ...sx }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
          },
          justifyContent: { xs: 'flex-start', sm: 'flex-start' },
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {heading ? (
            <TypographyEllipsis maxWidth={{ xs: 300, sm: 600 }} variant="h4">
              {heading}
            </TypographyEllipsis>
          ) : (
            !noTitle && <Skeleton width={100} height={36} variant="text" />
          )}

          {subHeader && (
            <Typography variant="caption" color="text.secondary">
              {subHeader}
            </Typography>
          )}
          {links?.length ? <Breadcrumbs links={links} {...other} /> : null}
        </Box>

        {action && <Box sx={{ flexShrink: 0, mt: { xs: 4, sm: 0 } }}>{action}</Box>}
        {info && <Box sx={{ flexShrink: 0, mt: { xs: 4, sm: 0 } }}>{info}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
