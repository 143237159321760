import { FIREBASE_API } from '@/config';
import { role } from '@/enum/roleAndPermission';
import { sessionStorageEnum } from '@/enum/sessionStorage';
import { getLastActiveWorkspace } from '@/services/API/workspace';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { collection, doc, getDoc, getFirestore, setDoc, where, query, getDocs } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';

const ADMIN_EMAILS = ['demo@minimals.cc'];

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isFullProfile: false,
  isLoadingUserProfile: false,
  user: null,
  id: null,
};

const reducer = (state, action) => {
  if (action.type === 'PROFILE_LOADING') {
    return {
      ...state,
      isLoadingUserProfile: true,
    };
  }
  if (action.type === 'PROFILE_LOADED') {
    return {
      ...state,
      isLoadingUserProfile: false,
    };
  }
  if (action.type === 'INITIALIZE') {
    const { user } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated: !!user,
      user,
    };
  }
  if (action.type === 'SET_FULL_PROFILE') {
    const { user, id } = action.payload;

    return {
      ...state,
      user,
      id,
      isFullProfile: true,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          dispatch({
            type: 'PROFILE_LOADING',
          });

          const userRef = doc(DB, 'main_user', user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            const user = docSnap.data();

            setProfile(user);
            getLastActiveWorkspace()
              .then(async (result) => {
                const { Data } = result.data;

                if (!!user?.uid && !!Data.CompanyId) {
                  await loadFullUserProfile(user?.uid, Data.CompanyId);
                }
              })
              .catch((e) => {
                console.error(e);
              })
              .finally(() => {
                dispatch({
                  type: 'PROFILE_LOADED',
                });
              });
          }

          dispatch({
            type: 'INITIALIZE',
            payload: { user },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              isInitialized: false,
              isFullProfile: false,
              user: null,
              id: null,
            },
          });
        }
      }),
    [dispatch]
  );

  const login = async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  };

  const loadFullUserProfile = async (mainUserId, companyId) => {
    const q = query(
      collection(DB, 'user'),
      where('main_user', '==', doc(DB, 'main_user', mainUserId)),
      where('company', '==', doc(DB, 'company', companyId))
    );

    const result = await getDocs(q);

    if (result?.size) {
      const state = result.docs[0].data()?.role?.includes(role.ADMIN) ?? false;
      dispatch({
        type: 'SET_FULL_PROFILE',
        payload: { isAuthenticated: state, id: result.docs[0].id, user: result.docs[0].data() },
      });
    } else {
      throw Error('User not found');
    }
  };

  const register = (email, password, firstName, lastName) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'main_user'), res.user?.uid);

      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
      });
    });

  const logout = async () => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        isInitialized: false,
        isFullProfile: false,
        user: null,
        id: null,
      },
    });
    await signOut(AUTH);
    await sessionStorage.removeItem(sessionStorageEnum.activeWorkspaceId);
    localStorage.removeItem('permission');
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          mainUserId: state?.user?.uid,
          id: state?.id,
          completedTutorial: profile?.completedTutorial || [],
          email: state?.user?.email,
          imgUrl: profile?.image_url,
          displayName: profile?.display_name,
          firstName: profile?.first_name,
          lastName: profile?.last_name,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : 'user',
          phoneNumber: profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          profile,
        },
        login,
        register,
        logout,
        loadFullUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider, AUTH, DB };
