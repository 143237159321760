import ScrollToTop from '@/components/atom/ScrollToTop';
import MotionLazyContainer from '@/components/molecules/animate/MotionLazyContainer';
import NotistackProvider from '@/components/template/NotistackProvider';
import { AuthorizedProvider } from './components/molecules/dialog/AuthorizedProvider';
import { PermissionModal } from './components/molecules/dialog/Permission';
import AlertToolbar from './components/organisms/header/AlertBar';
import { AlertBarProvider } from './contexts/AlertBarContext';
import { PermissionProvider } from './contexts/Permission';
import { WorkspaceProvider } from './contexts/WorkspaceContext';
import Router from './routes';
import ThemeProvider from './theme';

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <AlertBarProvider>
            <WorkspaceProvider>
              <PermissionProvider>
                <AuthorizedProvider />
                <PermissionModal />
                <AlertToolbar />
                <ScrollToTop />
                <Router />
              </PermissionProvider>
            </WorkspaceProvider>
          </AlertBarProvider>
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
