import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  isSuspended: false,
};

const slice = createSlice({
  name: 'authorized',
  initialState,
  reducers: {
    onSuspended(state) {
      state.isSuspended = true;
    },

    onResetSuspended(state) {
      state.isSuspended = false;
    },
  },
});

export default slice.reducer;

export function onSuspendedAuthorized() {
  return () => {
    dispatch(slice.actions.onSuspended());
  };
}

export function onResetAuthorized() {
  return () => {
    dispatch(slice.actions.onResetSuspended());
  };
}

export const { onSuspended, onResetSuspended } = slice.actions;
