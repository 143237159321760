import AuthGuard from '@/guards/AuthGuard';
import { Loadable } from '@/utils/loadableComponent';
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router';
import { PATH_MAIN } from '../paths';

const UserInvitationUploadSuccess = Loadable(lazy(() => import('@/pages/users/invitation/invitation-upload-success')));

export default {
  path: '/',
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_MAIN.dashboard.root} replace />, index: true },
    {
      path: PATH_MAIN.users.root,
      children: [
        { element: <Navigate to="/users/list" replace />, index: true },
        {
          path: PATH_MAIN.users.invitationUpload,
          element: <Outlet />,
          children: [{ path: 'success', element: <UserInvitationUploadSuccess /> }],
        },
      ],
    },
  ],
};
