import { LoginForm } from '@/components/organisms/auth/login';
import Page from '@/components/template/Page';
import useWorkspaceContext from '@/hooks/useWorkspaceContext';
import { PATH_EXTERNAL } from '@/routes/paths';
import { Box, Button, Container, Divider, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(() => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const ExternalLinkStyle = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

const ExternalSecondaryLinkStyle = styled(Link)(({ theme }) => ({
  color: theme.palette.other.linkedIn,
}));

const ContainerLoginStyled = styled('div')(({ theme }) => ({
  borderRadius: 16,
  boxShadow: theme.shadows[15],
  padding: theme.spacing(6, 5),
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6, 2),
  },
}));

export default function Login() {
  const { companyId } = useWorkspaceContext();
  return (
    <Page title="Athena for Business">
      <RootStyle>
        <Container
          maxWidth="sm"
          sx={{
            paddingRight: {
              xs: '16px',
            },
            paddingLeft: {
              xs: '16px',
            },
          }}
        >
          <ContentStyle>
            <Box alignSelf="center">
              <img src={'/logo/athena-business-grey.svg'} alt="athena logo" height={48} />
            </Box>
            <ContainerLoginStyled>
              <Stack direction="row" alignItems="center" textAlign="center" sx={{ mb: 3 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Welcome to My Back Office
                  </Typography>
                </Box>
              </Stack>
              <LoginForm />
              <Box textAlign="center" mt={2}>
                <ExternalLinkStyle href={`${PATH_EXTERNAL.storefront.login}?companyId=${companyId}`} target="_blank">
                  <Button variant="outlined" size="large" fullWidth color="inherit">
                    <Typography variant="inherit" color="text.primary">
                      Go to Athena for Business
                    </Typography>
                  </Button>
                </ExternalLinkStyle>
              </Box>
            </ContainerLoginStyled>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Typography variant="body2">
                <ExternalSecondaryLinkStyle target="_blank" href={PATH_EXTERNAL.privacyPolicy}>
                  Privacy Policy
                </ExternalSecondaryLinkStyle>
              </Typography>
              <Divider orientation="vertical" sx={{ mx: 3, height: 24 }} />
              <Typography variant="body2">
                <ExternalSecondaryLinkStyle target="_blank" href={PATH_EXTERNAL.termsOfCondition}>
                  Terms of Service
                </ExternalSecondaryLinkStyle>
              </Typography>
            </Stack>
            <Stack mt={3} alignItems="center">
              <Typography variant="body2">
                Have trouble logging in?
                <ExternalSecondaryLinkStyle sx={{ ml: 1 }} target="_blank" href={PATH_EXTERNAL.contactUs}>
                  Contact Us
                </ExternalSecondaryLinkStyle>
              </Typography>
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
