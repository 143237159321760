import SvgIconStyle from '@/components/atom/SvgIconStyle';
import { IconButtonAnimate } from '@/components/molecules/animate';
import MenuPopover from '@/components/molecules/MenuPopover';
import MyAvatar from '@/components/molecules/MyAvatar';
import useAuth from '@/hooks/useAuth';
import useIsMountedRef from '@/hooks/useIsMountedRef';
import useWorkspaceContext from '@/hooks/useWorkspaceContext';
import { PATH_AUTH, PATH_EXTERNAL } from '@/routes/paths';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Box, Divider, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WorkspaceContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflowWrap: 'anywhere',
}));

const WorkspaceWrapper = styled(Box)(() => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const AssetWorkSpaceIcon = '/assets/icons/ic_workspace.svg';
const AssetUserGroupIcon = '/assets/icons/ic_user_group.svg';
const AssetPhoneIcon = '/assets/icons/ic_call.svg';

const MENU_OPTIONS = [
  {
    label: 'Read Guidebook',
    linkTo: PATH_EXTERNAL.guidebook,
    icon: <MenuBookIcon />,
  },
  // {
  //   label: 'See Price Plans',
  //   linkTo: process.env.REACT_APP_PRICE_PLAN,
  //   icon: <ReceiptIcon/>
  // },
  {
    label: 'Contact Us',
    linkTo: PATH_EXTERNAL.contactUs,
    icon: <SvgIconStyle src={AssetPhoneIcon} />,
  },
  {
    label: 'About',
    linkTo: PATH_EXTERNAL.aboutUs,
    icon: <SvgIconStyle src={AssetUserGroupIcon} />,
  },
];

export default function AccountPopover() {
  const { companyId } = useWorkspaceContext();

  const navigate = useNavigate();

  const { user, logout, isLoadingUserProfile } = useAuth();

  const displayName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const { workspaceName } = useWorkspaceContext();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {isLoadingUserProfile ? (
          <Skeleton width={40} height={40} variant="circular" />
        ) : (
          <MyAvatar imgUrl={user?.imgUrl} name={displayName} />
        )}
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          py: 1,
          mt: 1.5,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 3 }}>
          {isLoadingUserProfile ? (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </>
          ) : (
            <>
              <Typography variant="subtitle2" noWrap>
                {displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </>
          )}
        </Box>

        <Divider />
        <Stack sx={{ p: 1 }}>
          <MenuItem
            sx={{ alignItems: 'center' }}
            href={`${PATH_EXTERNAL.storefront.root}?companyId=${companyId}`}
            component="a"
            target="_blank"
            onClick={handleClose}
          >
            <SvgIconStyle src={AssetWorkSpaceIcon} />
            <Typography pl={2} variant="body2">
              Go to My Workspace
            </Typography>
          </MenuItem>
        </Stack>
        <Box pl={8} pb={1} pr={1}>
          {!workspaceName ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <WorkspaceContainer>
              <WorkspaceWrapper>
                <Typography variant="caption" color="text.secondary" component="div">
                  {workspaceName}
                </Typography>
              </WorkspaceWrapper>
            </WorkspaceContainer>
          )}
        </Box>

        <Divider />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} href={option.linkTo} component="a" target="_blank">
              {option.icon}
              <Typography ml={2} variant="body2">
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Stack>

        <Divider />
        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleLogout}>
            <MeetingRoomIcon />
            <Typography pl={2} variant="body2">
              Logout
            </Typography>
          </MenuItem>
        </Stack>
      </MenuPopover>
    </>
  );
}
