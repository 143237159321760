import { LIVE, sessionStatusConfig } from '@/enum/session';
import dayjs from 'dayjs';

export const parseSessionStatus = (options) => {
  const { status, startDateTime } = options || {};
  if (status === sessionStatusConfig.CONFIRM && dayjs().isAfter(dayjs(startDateTime))) {
    return LIVE;
  }

  return status;
};
