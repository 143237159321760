import { Info, InfoOutlined, WarningOutlined, WarningRounded } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const HelperText = ({ text, outlined = false, info = false }) => {
  const InfoIcon = outlined ? InfoOutlined : Info;
  const ErrorIcon = outlined ? WarningOutlined : WarningRounded;
  const Icon = info ? InfoIcon : ErrorIcon;

  return (
    <Grid container pl={1.5} display="flex" component="span" wrap="nowrap">
      <Icon display="flex" fontSize="small" color={info ? 'text.secondary' : 'error'} />
      <Box ml={0.5} my={0.125}>
        {text}
      </Box>
    </Grid>
  );
};

HelperText.propTypes = {
  text: PropTypes.string,
  outlined: PropTypes.bool,
  info: PropTypes.bool,
};

export default HelperText;
