import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// we need to use forwardRef and destruct props in order to allow <Tooltip/> to work on this component
const TypographyEllipsis = React.forwardRef(
  ({ maxWidth, children, multipleLine = false, maxLine = 1, ...props }, ref) => {
    return (
      <Typography
        {...props}
        ref={ref}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth,
          ...(multipleLine && {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: String(maxLine),
            whiteSpace: 'pre-wrap',
          }),
        }}
      >
        {children}
      </Typography>
    );
  }
);

TypographyEllipsis.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  multipleLine: PropTypes.bool,
  maxLine: PropTypes.number,
};

export default TypographyEllipsis;
