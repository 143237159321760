// @mui
import { Box, List } from '@mui/material';
import PropTypes from 'prop-types';
// hooks
import useLocales from '../../../../hooks/useLocales';
import NavList from './NavList';
//
import { ListSubheaderStyle } from './style';

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse, ...other }) {
  const { translate } = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2, pt: isCollapse && 1 }}>
          {
            <ListSubheaderStyle
              sx={{
                display: isCollapse && 'none',
                pt: 3,
                pb: 1.5,
              }}
            >
              {translate(group.subheader)}
            </ListSubheaderStyle>
          }

          {group.items.map((list) => (
            <NavList
              key={list.title + list.path}
              data={list}
              depth={1}
              hasChildren={!!list.children}
              isCollapse={isCollapse}
            />
          ))}
        </List>
      ))}
    </Box>
  );
}
