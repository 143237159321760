import { Suspense } from 'react';
import { useLocation } from 'react-router';
import LoadingScreen from '@/components/template/LoadingScreen';

export const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};
