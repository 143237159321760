import { KeyboardArrowDownRounded } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function Select() {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownRounded,
      },
    },
  };
}
