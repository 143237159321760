import { permissionEnum } from '@/enum/permission';
import AuthGuard from '@/guards/AuthGuard';
import PermissionGuard from '@/guards/PermissionGuard';
import DashboardLayout from '@/layouts';
import navConfig from '@/layouts/navbar/NavConfig';
import { Loadable } from '@/utils/loadableComponent';
import { lazy } from 'react';
import { Navigate } from 'react-router';
import { PATH_MAIN } from 'routes/paths';

const DashboardPage = Loadable(lazy(() => import('@/pages/dashboard/index')));
const TrendingAreaOfExpertise = Loadable(lazy(() => import('@/pages/dashboard/trending-area-of-expertise/index')));
const TrendingAreaOfExpertiseDetail = Loadable(
  lazy(() => import('@/pages/dashboard/trending-area-of-expertise/detail/index'))
);
const TrendingAreaOfExpertiseDetailContributor = Loadable(
  lazy(() => import('@/pages/dashboard/trending-area-of-expertise/detail/Contributor'))
);
const TrendingAreaOfExpertiseDetailTopic = Loadable(
  lazy(() => import('@/pages/dashboard/trending-area-of-expertise/detail/Topic'))
);
const LeaderBoard = Loadable(lazy(() => import('@/pages/dashboard/leader-board')));
const TopUsers = Loadable(lazy(() => import('@/pages/dashboard/leader-board/TopUsers')));
const TopContributors = Loadable(lazy(() => import('@/pages/dashboard/leader-board/TopContributors')));

const UserList = Loadable(lazy(() => import('@/pages/users/user-list/')));
const UserProfile = Loadable(lazy(() => import('@/pages/users/user-profile/')));
const UserProfileInfo = Loadable(lazy(() => import('@/pages/users/user-profile/ProfileInfo')));
const UserTopic = Loadable(lazy(() => import('@/pages/users/user-profile/Topic')));
const UserExperience = Loadable(lazy(() => import('@/pages/users/user-profile/Experience')));
const UserSession = Loadable(lazy(() => import('@/pages/users/user-profile/Session')));
const UserSessionUser = Loadable(lazy(() => import('@/pages/users/user-profile/Session/SessionUser')));
const UserSessionContributor = Loadable(lazy(() => import('@/pages/users/user-profile/Session/SessionContributor')));
const EditUserProfile = Loadable(lazy(() => import('@/pages/users/edit-user/EditProfile')));

const AreaOfExpertise = Loadable(lazy(() => import('@/pages/users/area-of-expertise')));
const ViewDomainOfExpertise = Loadable(lazy(() => import('@/pages/users/area-of-expertise/ViewAreaOfExpertise')));
const CreateExpertise = Loadable(lazy(() => import('@/pages/users/area-of-expertise/CreateExpertise')));
const EditExpertise = Loadable(lazy(() => import('@/pages/users/area-of-expertise/EditExpertise')));

/** user invitation page */
const Invitation = Loadable(lazy(() => import('@/pages/users/invitation')));

const CreateDomainOfExpertise = Loadable(lazy(() => import('@/pages/users/area-of-expertise/CreateDomainOfExpertise')));

// const ComingSoon = Loadable(lazy(() => import('@/pages/coming-soon')));
const BusinessUnit = Loadable(lazy(() => import('@/pages/organization/business-unit')));
const BusinessUnitAction = Loadable(lazy(() => import('@/pages/organization/business-unit/action')));

const Department = Loadable(lazy(() => import('@/pages/organization/department')));
const DepartmentAction = Loadable(lazy(() => import('@/pages/organization/department/action')));

const Team = Loadable(lazy(() => import('@/pages/organization/team')));
const TeamAction = Loadable(lazy(() => import('@/pages/organization/team/action')));

const JobLevel = Loadable(lazy(() => import('@/pages/organization/job-level')));
const JobLevelAction = Loadable(lazy(() => import('@/pages/organization/job-level/action')));

const SessionPage = Loadable(lazy(() => import('@/pages/session')));
const SessionDetail = Loadable(lazy(() => import('@/pages/session/SessionDetail')));

const RoleAndPermission = Loadable(lazy(() => import('@/pages/settings/role-and-permission')));
const GuestRequest = Loadable(lazy(() => import('@/pages/request/guest')));

export default {
  path: '/',
  element: (
    <AuthGuard>
      <DashboardLayout navConfig={navConfig} />
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to={PATH_MAIN.dashboard.root} replace />, index: true },
    {
      path: PATH_MAIN.dashboard.root,
      children: [
        { element: <Navigate to={PATH_MAIN.dashboard.overview} replace />, index: true },
        {
          path: PATH_MAIN.dashboard.overview,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_VIEW}>
              <DashboardPage />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.dashboard.trendingAreaExpertise,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_VIEW}>
              <TrendingAreaOfExpertise />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.dashboard.trendingAreaExpertiseDetail,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_VIEW}>
              <TrendingAreaOfExpertiseDetail />
            </PermissionGuard>
          ),
          children: [
            {
              path: ':expertiseId/topic',
              element: <TrendingAreaOfExpertiseDetailTopic />,
            },
            {
              path: ':expertiseId/contributor',
              element: <TrendingAreaOfExpertiseDetailContributor />,
            },
          ],
        },
        {
          path: PATH_MAIN.dashboard.leaderBoard,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_VIEW}>
              <LeaderBoard />
            </PermissionGuard>
          ),
          children: [
            {
              path: 'users',
              element: <TopUsers />,
            },
            {
              path: 'contributors',
              element: <TopContributors />,
            },
          ],
        },
      ],
    },
    {
      path: PATH_MAIN.session.root,
      children: [
        {
          path: PATH_MAIN.session.root,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_SESSION_VIEW}>
              <SessionPage />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.session.detail,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_SESSION_VIEW}>
              <SessionDetail />{' '}
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.users.root,
      children: [
        {
          element: <Navigate to="/users/list" replace />,
          index: true,
        },
        {
          path: PATH_MAIN.users.list,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_USER_ONBOARD_VIEW}>
              <UserList />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.users.editUser,
          children: [
            {
              path: 'profile',
              element: (
                <PermissionGuard requirePermission={permissionEnum.BO_USER_ONBOARD_VIEW}>
                  <EditUserProfile />
                </PermissionGuard>
              ),
            },
          ],
        },
        {
          path: PATH_MAIN.users.detail,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_USER_ONBOARD_VIEW}>
              <UserProfile />
            </PermissionGuard>
          ),
          children: [
            { path: '', element: <UserProfileInfo /> },
            { path: 'topics', element: <UserTopic /> },
            { path: 'experience', element: <UserExperience /> },
            {
              path: 'sessions',
              element: <UserSession />,
              children: [
                { path: '', element: <UserSessionUser /> },
                { path: 'contributor', element: <UserSessionContributor /> },
              ],
            },
          ],
        },
        {
          path: PATH_MAIN.users.areaOfExpertise,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_DOMAIN_EXPERTISE_VIEW}>
              <AreaOfExpertise />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.users.invitation,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_USER_INVITE_VIEW}>
              <Invitation />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.users.createDomainAreaOfExpertise,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_DOMAIN_EXPERTISE_ADD}>
              <CreateDomainOfExpertise />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.users.viewExpertiseGroup,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_EXPERTISE_VIEW}>
              <ViewDomainOfExpertise />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.users.createExpertise,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_EXPERTISE_ADD}>
              <CreateExpertise />
            </PermissionGuard>
          ),
        },
        {
          path: PATH_MAIN.users.editExpertise,
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_EXPERTISE_EDIT}>
              <EditExpertise />
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.organization.root,
      children: [
        { element: <Navigate to="/organization/business-unit" replace />, index: true },
        {
          path: 'business-unit',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_BUSINESS_UNIT_VIEW}>
              <BusinessUnit />
            </PermissionGuard>
          ),
        },
        {
          path: 'business-unit/:state',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_BUSINESS_UNIT_ADD}>
              <BusinessUnitAction />
            </PermissionGuard>
          ),
        },
        {
          path: 'business-unit/:state/:id',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_BUSINESS_UNIT_EDIT}>
              <BusinessUnitAction />
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.organization.root,
      children: [
        { element: <Navigate to="/organization/department" replace />, index: true },
        {
          path: 'department',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_DEPARTMENT_VIEW}>
              <Department />
            </PermissionGuard>
          ),
        },
        {
          path: 'department/:state',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_DEPARTMENT_ADD}>
              <DepartmentAction />
            </PermissionGuard>
          ),
        },
        {
          path: 'department/:state/:id',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_DEPARTMENT_EDIT}>
              <DepartmentAction />
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.organization.root,
      children: [
        { element: <Navigate to="/organization/team" replace />, index: true },
        {
          path: 'team',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_TEAM_VIEW}>
              <Team />
            </PermissionGuard>
          ),
        },
        {
          path: 'team/:state',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_TEAM_ADD}>
              <TeamAction />
            </PermissionGuard>
          ),
        },
        {
          path: 'team/:state/:id',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_TEAM_EDIT}>
              <TeamAction />
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.organization.root,
      children: [
        { element: <Navigate to="/organization/job-level" replace />, index: true },
        {
          path: 'job-level',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_JOB_LEVEL_VIEW}>
              <JobLevel />
            </PermissionGuard>
          ),
        },
        {
          path: 'job-level/:state',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_JOB_LEVEL_ADD}>
              <JobLevelAction />
            </PermissionGuard>
          ),
        },
        {
          path: 'job-level/:state/:id',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_JOB_LEVEL_EDIT}>
              <JobLevelAction />{' '}
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.request.root,
      children: [
        { element: <Navigate to="/request/guest" replace />, index: true },
        {
          path: 'guest',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_GUEST_REQUEST_VIEW}>
              <GuestRequest />
            </PermissionGuard>
          ),
        },
      ],
    },
    {
      path: PATH_MAIN.settings.root,
      children: [
        { element: <Navigate to="/settings/role-and-permission" replace />, index: true },
        {
          path: 'role-and-permission',
          element: (
            <PermissionGuard requirePermission={permissionEnum.BO_ROLE_SETTING_VIEW}>
              <RoleAndPermission />
            </PermissionGuard>
          ),
        },
      ],
    },
  ],
};
