import PropTypes from 'prop-types';
// utils
import createAvatar from '../../utils/createAvatar';
//
import Avatar from '../atom/Avatar';

MyAvatar.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string,
};

export default function MyAvatar({ imgUrl, name, ...other }) {
  return (
    <Avatar src={imgUrl} alt={name} color={imgUrl ? 'default' : createAvatar(name).color} {...other}>
      {createAvatar(name || '').name}
    </Avatar>
  );
}
