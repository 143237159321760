import axiosInstance from '@/services/API/axios';

export const fetchUserList = async () => {
  const res = await axiosInstance.get('/user');
  return res.data;
};

export const fetchUserDetail = async (id) => {
  const res = await axiosInstance.get(`/user/${id}`);
  return res.data;
};

export const fetchUserAreaOfExpertise = async (id) => {
  const res = await axiosInstance.get(`/user/${id}/area-of-expertise`);
  return res.data;
};

export const fetchUserAreaOfDevelopment = async (id) => {
  const res = await axiosInstance.get(`/user/${id}/area-of-development`);
  return res.data;
};

export const fetchUserTopic = async (id) => {
  const res = await axiosInstance.get(`/user/${id}/topic`);
  return res.data;
};

export const fetchUserExperience = async (id) => {
  const res = await axiosInstance.get(`/user/${id}/experience`);
  return res.data;
};

export const fetchUserSession = async (id, options) => {
  const { isContributor = false } = options || {};
  const query = `?Role=${isContributor ? 'MENTOR' : 'MENTEE'}`;
  const res = await axiosInstance.get(`/user/${id}/session${query}`);
  return res.data;
};

export const fetchUserPermission = async (id) => {
  const res = await axiosInstance.get(`/user/${id}/permission`);
  return res.data;
};

export const fetchSearchUser = async (query) => {
  const res = await axiosInstance.get(`/user/search`, {
    Query: query,
  });
  return res.data;
};

export const updateUserProfile = async (
  userId,
  { isGuest, jobTitle, jobLevelId, businessUnitId, departmentId, teamId, guestOrganizationName }
) => {
  const payload = isGuest
    ? {
        IsGuest: isGuest,
        JobTitle: jobTitle,
        JobLevelId: jobLevelId,
        GuestOrganizationName: guestOrganizationName,
      }
    : {
        IsGuest: isGuest,
        JobTitle: jobTitle,
        JobLevelId: jobLevelId,
        BusinessUnitId: businessUnitId,
        DepartmentId: departmentId,
        TeamId: teamId,
      };

  const response = await axiosInstance.put(`/user/${userId}/edit`, payload);

  return response.data;
};

export const setUserSuspend = async (userId) => {
  const response = await axiosInstance.patch('/user/status', {
    UserId: userId,
    Status: 'Suspended',
  });

  return response.data;
};

export const validateSuspendedAccount = (email = '') => {
  return axiosInstance.post('/user/validate-suspended', {
    Email: email,
  });
};
