import Scrollbar from '@/components/atom/Scrollbar';
import MultipleWorkspaceForm from '@/components/organisms/user/workspace/MultipleWorkspaceForm';
import { NavSectionVertical } from '@/components/template/nav-section';
import { NAVBAR } from '@/config';
import useAlertBar from '@/hooks/useAlertBar';
import useCollapseDrawer from '@/hooks/useCollapseDrawer';
import useMultipleWorkspace from '@/hooks/user/useMultipleWorkspace';
import useResponsive from '@/hooks/useResponsive';
import useWorkspaceContext from '@/hooks/useWorkspaceContext';
import NavConfig from '@/layouts/navbar/NavConfig';
import cssStyles from '@/utils/cssStyles';
import { Box, Divider, Drawer, Skeleton, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import CollapseButton from './CollapseButton';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const WorkspaceContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  overflowWrap: 'anywhere',
  width: '100%',
}));

const WorkspaceWrapper = styled(Box)(() => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  navConfig: PropTypes.array,
};

const SPACE_BETWEEN_LINK_AND_BOTTOM = 24;
export default function NavbarVertical({ isOpenSidebar, onCloseSidebar, navConfig = NavConfig }) {
  const theme = useTheme();

  const { showAlert, height } = useAlertBar();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { workspaceName, isLoadingWorkspaceName } = useWorkspaceContext();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const { isWorkspaceLoading, workspaceList, handleSelectChangeWorkspace, activeWorkspaceId } = useMultipleWorkspace();

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 'auto', display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 3,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        {isDesktop && (
          <Stack alignItems="flex-end">
            <CollapseButton onToggleCollapse={onToggleCollapse} isCollapse={isCollapse} collapseClick={collapseClick} />
          </Stack>
        )}
        <Box pb={isDesktop && isCollapse && 2}>
          <RouterLink to="/">
            {isDesktop && isCollapse ? (
              <img alt="logo" src={'/logo/athena-logo-collapse.svg'} height={30} />
            ) : (
              <img alt="logo" src={'/logo/athena-business-grey.svg'} height={30} />
            )}
          </RouterLink>
        </Box>

        <Stack display={isCollapse ? 'none' : 'visible'}>
          {workspaceList.length === 0 && !isWorkspaceLoading ? (
            <Box>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="caption" color="text.secondary">
                  Your Workspace:
                </Typography>
              </Stack>
              <WorkspaceContainer>
                <WorkspaceWrapper>
                  {isLoadingWorkspaceName ? (
                    <Skeleton variant="rectangular" height="43" />
                  ) : (
                    <Typography variant="caption">{workspaceName ?? '-'}</Typography>
                  )}
                </WorkspaceWrapper>
              </WorkspaceContainer>
            </Box>
          ) : (
            <MultipleWorkspaceForm
              workspaceList={workspaceList}
              isWorkspaceLoading={isWorkspaceLoading}
              onChangeWorkspace={handleSelectChangeWorkspace}
              value={activeWorkspaceId}
            />
          )}
        </Stack>
      </Stack>

      <Divider variant="middle" />

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              marginTop: showAlert ? `${height}px` : 'unset',
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              height: `calc(100% - ${height + SPACE_BETWEEN_LINK_AND_BOTTOM}px)`,
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
