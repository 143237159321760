import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Box, styled } from '@mui/system';
import PropTypes from 'prop-types';

const AlertWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  textAlign: 'center',

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingRight: theme.spacing(4),
  },
}));

const ContentContainer = styled(Box)(() => ({
  display: 'flex',
}));

const CloseContainer = styled(Box)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(0.25),
  },
}));

const TrialBarAlert = ({
  alertIcon: AlertIcon = () => {},
  alertIconColor,
  closeIconColor,
  children = null,
  onClose = () => {},
}) => {
  return (
    <AlertWrapper>
      <ContentContainer>
        <Box mr={1} display="flex" py={0.25}>
          <AlertIcon fontSize="small" color={alertIconColor} />
        </Box>
        <Box display="inline-flex" flexWrap="wrap" alignItems="center">
          {children}
        </Box>
      </ContentContainer>
      <CloseContainer ml={1}>
        <IconButton onClick={onClose} sx={{ p: 0, color: closeIconColor }}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </CloseContainer>
    </AlertWrapper>
  );
};

TrialBarAlert.propTypes = {
  alertIcon: PropTypes.object,
  alertIconColor: PropTypes.string,
  closeIconColor: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.element,
};

export default TrialBarAlert;
