import { useContext } from 'react';
import { PermissionContext } from '@/contexts/Permission';

const usePermission = () => {
  const context = useContext(PermissionContext);

  if (!context) throw new Error('Permission context must be use inside PermissionProvider');

  return context;
};

export default usePermission;
