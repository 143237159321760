function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOT_MAIN = '/';

export const PATH_EXTERNAL = {
  storefront: {
    root: process.env.REACT_APP_STOREFRONT_URL,
    login: `${process.env.REACT_APP_STOREFRONT_URL}/login`,
    forgotPassword: `${process.env.REACT_APP_STOREFRONT_URL}/forgot-password`,
  },
  privacyPolicy: process.env.REACT_APP_PRIVACY_POLICY_URL,
  termsOfCondition: process.env.REACT_APP_TERMS_OF_SERVICE_URL,
  contactUs: process.env.REACT_APP_CONTACT_US,
  aboutUs: process.env.REACT_APP_ABOUT_US,
  guidebook: process.env.REACT_APP_GUIDEBOOK,
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  registerSuccess: path(ROOTS_AUTH, '/register/success'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_MAIN = {
  root: ROOT_MAIN,
  dashboard: {
    root: path(ROOT_MAIN, 'dashboard'),
    overview: path(ROOT_MAIN, 'dashboard/overview'),
    trendingAreaExpertise: path(ROOT_MAIN, 'dashboard/trending-area-of-expertise'),
    trendingAreaExpertiseDetail: path(ROOT_MAIN, 'dashboard/trending-area-of-expertise/detail'),
    leaderBoard: path(ROOT_MAIN, 'dashboard/leader-board'),
    topUsers: path(ROOT_MAIN, 'dashboard/leader-board/users'),
    topContributors: path(ROOT_MAIN, 'dashboard/leader-board/contributors'),
  },
  session: {
    root: path(ROOT_MAIN, 'sessions'),
    detail: path(ROOT_MAIN, 'sessions/:sessionId'),
  },
  users: {
    root: path(ROOT_MAIN, 'users'),
    list: path(ROOT_MAIN, 'users/list'),
    detail: path(ROOT_MAIN, 'users/list/:userId'),
    editUser: path(ROOT_MAIN, 'users/list/:userId/edit'),
    invitation: path(ROOT_MAIN, 'users/invitation'),
    invitationUpload: path(ROOT_MAIN, 'users/invitation/upload'),
    invitationUploadForm: path(ROOT_MAIN, 'users/invitation/upload/form'),
    invitationUploadSuccess: path(ROOT_MAIN, '/users/invitation/upload/success'),
    createUser: path(ROOT_MAIN, 'users/create'),
    areaOfExpertise: path(ROOT_MAIN, 'users/area-of-expertise'),
    createDomainAreaOfExpertise: path(ROOT_MAIN, 'users/area-of-expertise/domain/create'),
    viewExpertiseGroup: path(ROOT_MAIN, 'users/area-of-expertise/domain/:expertiseDomainId'),
    createExpertise: path(ROOT_MAIN, 'users/area-of-expertise/domain/:expertiseDomainId/create'),
    editExpertise: path(ROOT_MAIN, 'users/area-of-expertise/domain/:expertiseDomainId/:expertiseId/edit'),
  },
  organization: {
    root: path(ROOT_MAIN, 'organization'),
    businessUnit: path(ROOT_MAIN, 'organization/business-unit'),
    department: path(ROOT_MAIN, 'organization/department'),
    team: path(ROOT_MAIN, 'organization/team'),
    jobLevel: path(ROOT_MAIN, 'organization/job-level'),
  },
  request: {
    root: path(ROOT_MAIN, 'request'),
    guest: path(ROOT_MAIN, 'request/guest'),
  },
  settings: {
    root: path(ROOT_MAIN, 'settings'),
    roleAndPermission: path(ROOT_MAIN, 'settings/role-and-permission'),
  },
};
