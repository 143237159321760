import PropTypes from 'prop-types';
import useAuth from '@/hooks/useAuth';
import LoadingScreen from '@/components/template/LoadingScreen';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isInitialized } = useAuth();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
