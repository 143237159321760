import { getWorkspace } from '@/services/API/workspace';
import { useCallback, useState } from 'react';

export default function useWorkspace() {
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const [workspace, setWorkspace] = useState({
    name: '',
    status: '',
    inactiveAt: '',
    expiredAt: '',
  });

  const fetchWorkspace = useCallback(async (workspaceId = '') => {
    setIsLoading(true);
    try {
      const response = await getWorkspace(workspaceId);
      setWorkspace(response);

      return response;
    } catch (error) {
      console.log(error);
      setWorkspace({
        name: '',
        status: '',
        inactiveAt: '',
        expiredAt: '',
      });
      setError(error);
      return {};
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    fetchWorkspace,
    error,
    isLoading,
    ...workspace,
  };
}
