export const FORBIDDEN_CODE = 403;
export const UNAUTHORIZED_CODE = 401;

export const permissionErrorCode = Object.freeze({
  permissionHasInvoke: 4031,
  permissionHasDenied: 4032,
});

export const unauthorizedErrorCode = Object.freeze({
  accountSuspended: 4011,
});
