import HelperText from '@/components/atom/hook-form/HelperText';
// @mui
import { Box, FormHelperText, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  caption: PropTypes.string,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  inputProps: PropTypes.object,
};

export default function RHFTextField({ name, rows, multiline, caption, inputProps, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <TextField
            {...field}
            fullWidth
            rows={rows}
            inputProps={inputProps}
            multiline={multiline}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!other.disabled && !!error}
            helperText={!error && caption}
            {...other}
          />
          {!other.disabled && error && (
            <FormHelperText error component="div">
              <HelperText text={error.message} />
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
}
