import Iconify from '@/components/atom/Iconify';
import SvgIconStyle from '@/components/atom/SvgIconStyle';
import { permissionEnum } from '@/enum/permission';
import { PATH_MAIN } from '@/routes/paths';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_analytics'),
  session: getIcon('ic_radio_on'),
  user: getIcon('ic_user'),
  mentor: <Iconify icon="fa6-solid:user-group" />,
  organization: getIcon('ic_banking'),
  request: getIcon('list_alt_outline'),
  setting: <Iconify icon="eva:settings-2-outline" />,
};

const navConfig = [
  {
    subheader: 'Back Office',
    items: [
      {
        title: 'Dashboard',
        path: PATH_MAIN.dashboard.root,
        icon: ICONS.dashboard,
        children: [
          { title: 'Overview', path: PATH_MAIN.dashboard.overview },
          { title: 'Trending Areas of Expertise', path: PATH_MAIN.dashboard.trendingAreaExpertise },
          { title: 'Top Users', path: PATH_MAIN.dashboard.topUsers },
          { title: 'Top Contributors', path: PATH_MAIN.dashboard.topContributors },
        ],
      },
      {
        title: 'Sessions',
        path: PATH_MAIN.session.root,
        icon: ICONS.session,
      },
      {
        title: 'Users',
        path: PATH_MAIN.users.root,
        icon: ICONS.user,
        children: [
          {
            title: 'Onboarded Users',
            path: PATH_MAIN.users.list,
          },
          { title: 'Invited Users', path: PATH_MAIN.users.invitation, permission: permissionEnum.BO_USER_INVITE_VIEW },
          { title: 'Areas of Expertise', path: PATH_MAIN.users.areaOfExpertise },
        ],
      },
      {
        title: 'Organization',
        path: PATH_MAIN.organization.root,
        icon: ICONS.organization,
        children: [
          { title: 'Business Units', path: PATH_MAIN.organization.businessUnit },
          { title: 'Departments', path: PATH_MAIN.organization.department },
          { title: 'Teams', path: PATH_MAIN.organization.team },
          { title: 'Job Levels', path: PATH_MAIN.organization.jobLevel },
        ],
      },
      {
        title: 'Requests',
        path: PATH_MAIN.request.root,
        icon: ICONS.request,
        children: [
          {
            title: 'Guest Requests',
            path: PATH_MAIN.request.guest,
          },
        ],
      },
      {
        title: 'Settings',
        path: PATH_MAIN.settings.root,
        icon: ICONS.setting,
        children: [
          {
            title: 'Roles & Permissions',
            path: PATH_MAIN.settings.roleAndPermission,
          },
        ],
      },
    ],
  },
];

export default navConfig;
