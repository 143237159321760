import { onResetAuthorized } from '@/redux/slices/authorized';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '@/hooks/useAuth';
import { useNavigate } from 'react-router';
import { PATH_AUTH } from '@/routes/paths';
import { useCallback, useEffect } from 'react';
import { errorType } from '@/enum/error';

export const AuthorizedProvider = () => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isSuspended } = useSelector((state) => state.authorized);

  const handleAuthorizedSuspended = useCallback(async () => {
    dispatch(onResetAuthorized());
    await logout();
    navigate(`${PATH_AUTH.login}?error=${errorType.accountSuspended}`, { replace: true });
  }, [dispatch, logout, navigate]);

  useEffect(() => {
    if (isSuspended) {
      handleAuthorizedSuspended();
    }
  }, [handleAuthorizedSuspended, isSuspended]);

  return <></>;
};
