import TrialBarAlert from '@/components/molecules/trialBar/TrialBarAlert';
import { workspaceStatus } from '@/enum/workspaceStatus';
import useAlertBar from '@/hooks/useAlertBar';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const { active, inactive, expired, trial } = workspaceStatus;

const WorkspaceStatusContainer = styled(Box)(({ theme, status }) => {
  const background = {
    [trial]: theme.palette.info.lighter,
    [inactive]: theme.palette.error.lighter,

    // TODO: After 14 days trial epic
    // [workspaceStatusEnum.expired]: ''
  }[status];

  const color = {
    [trial]: theme.palette.info.darker,
    [inactive]: theme.palette.error.darker,

    // TODO: After 14 days trial epic
    // [workspaceStatusEnum.expired]: ''
  }[status];

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background,
    color,
    zIndex: 99,
    height: 'max-content',
    width: '100%',
    overflowWrap: 'anywhere',
    padding: '5px 16px',
    position: 'fixed',
  };
});

const TextButton = styled(Button)(() => ({
  padding: 0,
  minWidth: 'auto',
  height: 'auto',
  color: 'currentcolor',
  textTransform: 'initial',
  background: 'transparent',
  fontWeight: 400,
  textDecoration: 'underline',
  lineHeight: 1.43,

  '&:hover': {
    background: 'transparent',
    textDecoration: 'underline',
  },
}));

export default function AlertToolbar() {
  const { showAlert, status, expiredDate, onClose, height, ref } = useAlertBar();

  const theme = useTheme();

  if (!showAlert) {
    return null;
  }

  const urlWix = process.env.REACT_APP_CONTACT_US;

  switch (status) {
    case trial:
      return (
        <WorkspaceStatusContainer status={status} ref={ref} height={height}>
          <TrialBarAlert
            alertIcon={InfoIcon}
            alertIconColor="info"
            closeIconColor={theme.palette.info.darker}
            onClose={onClose}
          >
            <>
              <Typography variant="body2" component="span" mr={0.5} py={0.25}>
                Your 14-day trial will be expired by
              </Typography>
              <Typography variant="subtitle2" component="span" fontWeight={600} py={0.25}>
                {expiredDate}.
              </Typography>
            </>
          </TrialBarAlert>
        </WorkspaceStatusContainer>
      );
    case inactive:
      return (
        <WorkspaceStatusContainer status={status} ref={ref} height={height}>
          <TrialBarAlert
            alertIcon={InfoIcon}
            alertIconColor="error"
            closeIconColor={theme.palette.error.darker}
            onClose={onClose}
          >
            <>
              <Typography variant="body2" component="span" mr={0.5} py={0.25}>
                Your free trial has ended.
              </Typography>
              <Box display="inline-flex">
                <Typography variant="body2" component="span" mr={0.5} py={0.25}>
                  Please
                </Typography>
                <TextButton variant="text" href={urlWix} target="_blank">
                  <Typography variant="body2" component="span" py={0.25}>
                    contact Athena
                  </Typography>
                </TextButton>
                <Typography variant="body2" component="span" py={0.25}>
                  .
                </Typography>
              </Box>
            </>
          </TrialBarAlert>
        </WorkspaceStatusContainer>
      );
    case expired:
    case active:
    default:
      return null;
  }
}
