export const role = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  ANALYST: 'ANALYST',
};

export const displayRole = {
  [role.SUPER_ADMIN]: 'Super Admin',
  [role.ADMIN]: 'Admin',
  [role.ANALYST]: 'Analyst',
};

export const roleInformation = {
  [role.SUPER_ADMIN]: {
    title: displayRole[role.SUPER_ADMIN],
    describe:
      'View dashboard / session / user details, invite users, expertise mapping, set organization structure, add & manage all roles.',
  },
  [role.ADMIN]: {
    title: displayRole[role.ADMIN],
    describe:
      'View dashboard / session details / user details, Invite users, expertise mapping, set organization structure, add analyst role.',
  },
  [role.ANALYST]: {
    title: displayRole[role.ANALYST],
    describe: 'View dashboard / session details / user details / organization structure.',
  },
};
