import PropTypes from 'prop-types';
import usePermission from '@/hooks/usePermission';
import ForbiddenPage from '@/pages/forbidden';
import LoadingScreen from '@/components/template/LoadingScreen';

PermissionGuard.propTypes = {
  children: PropTypes.node,
  requirePermission: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default function PermissionGuard({ children, requirePermission }) {
  const { hasPermission, isLoading, initialize } = usePermission();

  if (isLoading || !initialize) {
    return <LoadingScreen />;
  }

  if (
    requirePermission &&
    typeof requirePermission !== 'string' &&
    requirePermission.some((require) => !hasPermission(require))
  ) {
    return <ForbiddenPage />;
  }

  if (requirePermission && !hasPermission(requirePermission)) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
}
