import { permissionAccess } from '@/utils/permissionAccess';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import useAuth from '@/hooks/useAuth';
import { fetchUserPermission } from '@/services/API/user';
import { initialStatePermission, reducerPermission } from '@/store/permission';
import { permissionEnum } from '@/enum/permission';

PermissionProvider.propTypes = {
  children: PropTypes.node,
};

const PermissionContext = createContext({
  permission: [],
  fetchPermission: () => Promise.resolve([]),
});

function PermissionProvider({ children }) {
  const auth = useAuth();
  const userId = auth.user.id;
  const [isLoading, setIsLoading] = useState(false);

  const [state, dispatch] = useReducer(reducerPermission, initialStatePermission);

  const enabledPermission = true;

  const fetchPermission = useCallback(async () => {
    setIsLoading(true);

    try {
      if (enabledPermission) {
        const permissions = await fetchUserPermission(userId);

        dispatch({
          type: 'INITIALIZE',
          payload: { permission: permissions.Data },
        });

        return permissions.Data;
        // eslint-disable-next-line no-else-return
      } else {
        const allPermission = Object.values(permissionEnum || {});

        dispatch({
          type: 'INITIALIZE',
          payload: { permission: allPermission },
        });

        return allPermission;
      }
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requirePermission = useMemo(() => {
    return permissionAccess(state.permission);
  }, [state.permission]);

  const hasPermission = useCallback(
    (require) => {
      if (typeof require === 'string') {
        return state.permission.some((item) => item === require);
      }

      return require.every((requireItem) => {
        return state.permission.some((item) => item === requireItem);
      });
    },
    [state.permission]
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  return (
    <PermissionContext.Provider
      value={{
        permission: state.permission,
        initialize: state.initialize,
        fetchPermission,
        requirePermission,
        hasPermission,
        isLoading,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
}

export { PermissionContext, PermissionProvider };
