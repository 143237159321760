import AdminPage from '@/components/template/AdminPage';
import { Box, Container, Typography } from '@mui/material';
import { PageLockIllustration } from '@/assets';

export default function ForbiddenPage() {
  return (
    <AdminPage title="Forbidden permission" pageTitle={null} noTitle>
      <Container>
        <Box sx={{ textAlign: 'center' }}>
          <PageLockIllustration sx={{ mb: (theme) => theme.spacing(2) }} />
          <Typography variant="h6" mb={(theme) => theme.spacing(0.5)}>
            You don’t have permission.
          </Typography>
          <Typography variant="body2">You must be granted the access in order to see this page.</Typography>
        </Box>
      </Container>
    </AdminPage>
  );
}
