// ----------------------------------------------------------------------

export default function TablePagination(theme) {
  return {
    MuiTablePagination: {
      styleOverrides: {
        menuItem: {
          margin: theme.spacing(0, 1),
        },
      },
    },
  };
}
