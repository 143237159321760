export const COMPLETE = 'Completed';
export const CONFIRM = 'Confirmed';
export const PENDING = 'Pending';
export const CANCELED = 'Canceled';
export const DECLINED = 'Declined';
export const SCHEDULED = 'Scheduled';
export const REJECTED = 'Rejected';
export const LIVE = 'Live now';

// data from server
export const sessionStatusConfig = {
  COMPLETE: 'Completed',
  CONFIRM: 'Confirmed',
  PENDING: 'Pending',
  CANCELED: 'Canceled',
  REJECTED: 'Rejected',
  LIVE: 'Live now',
};

// display
export const sessionStatusDisplay = {
  [sessionStatusConfig.COMPLETE]: COMPLETE,
  [sessionStatusConfig.CONFIRM]: SCHEDULED,
  [sessionStatusConfig.PENDING]: PENDING,
  [sessionStatusConfig.CANCELED]: CANCELED,
  [sessionStatusConfig.REJECTED]: DECLINED,
  [sessionStatusConfig.LIVE]: LIVE,
};

export const cancelReasonEnum = Object.freeze({
  rescheduleSession: 'reschedule',
  expired: 'expired',
  urgent: 'urgentAttend',
  alreadyAnswered: 'alreadyAnswered',
  others: 'others',
});

export const cancelReasonDisplay = {
  [cancelReasonEnum.rescheduleSession]: 'I want to reschedule the session.',
  [cancelReasonEnum.urgent]: 'I have an urgent matter to attend to at the time.',
  [cancelReasonEnum.alreadyAnswered]: 'I’ve already answered the question outside the platform.',
  [cancelReasonEnum.expired]: 'Request expired.',
  [cancelReasonEnum.others]: 'Other.',
};

export const rejectReasonEnum = Object.freeze({
  notFit: 'NotFit',
  conflictTime: 'ConflictTime',
  answeredQuestionOutsidePlatform: 'AnsweredQuestionOutsidePlatform',
  other: 'Other',
});

export const rejectReasonDisplay = {
  [rejectReasonEnum.notFit]: 'There is someone more fit to answer.',
  [rejectReasonEnum.conflictTime]: 'I have an urgent matter to attend to at the time.',
  [rejectReasonEnum.answeredQuestionOutsidePlatform]: 'I’ve already answered the question outside the platform.',
  [rejectReasonEnum.others]: 'Other.',
};

export const sessionSearchOption = Object.freeze([
  {
    value: 'topic',
    label: 'Topic',
  },
  {
    value: 'mentorFullName',
    label: 'Contributor ',
  },
  {
    value: 'participantName',
    label: 'Participant',
  },
]);

export const sessionStatusOption = Object.freeze([
  {
    value: 'all',
    label: 'All Status',
  },
  {
    value: PENDING,
    label: sessionStatusDisplay[PENDING],
  },
  {
    value: CONFIRM,
    label: sessionStatusDisplay[CONFIRM],
  },
  {
    value: LIVE,
    label: sessionStatusDisplay[LIVE],
  },
  {
    value: COMPLETE,
    label: sessionStatusDisplay[COMPLETE],
  },
  {
    value: REJECTED,
    label: sessionStatusDisplay[REJECTED],
  },
  {
    value: CANCELED,
    label: sessionStatusDisplay[CANCELED],
  },
]);

export const sessionParticipantRole = Object.freeze({
  MenteeInvited: '',
  MenteeOrganizer: 'Organizer',
  Contributor: 'Contributor',
});

export const sessionParticipantStatus = Object.freeze({
  confirmed: 'Confirmed',
  rejected: 'Rejected',
  pending: 'Pending',
});
