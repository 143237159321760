import { memo } from 'react';

import { Box } from '@mui/material';

function PageLockIllustration({ ...other }) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        maxwidth="338"
        height="269"
        viewBox="0 0 338 269"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 148.533C0 179.511 10.524 207.739 27.8421 229.073C47.7097 253.556 76.5198 268.948 108.634 269C122.669 269.022 136.089 266.096 148.416 260.753C154.789 257.99 161.923 258.307 168.096 261.581C175.337 265.416 183.445 267.567 192.007 267.567C195.544 267.567 199.008 267.197 202.358 266.495C211.922 264.5 220.624 259.778 227.771 253.09C232.263 248.885 238.016 246.772 243.895 246.779H243.995C263.422 246.779 281.468 240.254 296.45 229.073C309.844 219.09 320.782 205.389 328.069 189.302C334.423 175.284 338 159.448 338 142.688C338 85.2037 295.909 38.5965 243.995 38.5965C238.702 38.5965 233.517 39.0915 228.459 40.0151C215.065 16.0055 191.079 0 163.718 0C152.166 0 141.214 2.85266 131.411 7.96631C122.088 12.8136 113.799 19.7015 107.018 28.0955C83.994 28.502 62.7046 36.8305 45.28 50.73C17.8516 72.5966 0 108.259 0 148.533Z"
            fill="#D9B3F4"
          />
        </g>
        <path
          opacity="0.24"
          d="M99.9142 71C99.9142 71 33.3232 99.946 31.0313 140.251C28.7394 180.555 153.052 173.288 180.236 190.668C207.42 208.048 325.238 234.461 322.968 204.186C320.697 173.911 292.706 163.466 292.706 163.466"
          stroke="#AD5BE7"
          strokeWidth="2"
          strokeDasharray="3 3"
        />
        <path
          opacity="0.12"
          d="M274.175 204.152L233.555 95.7617C232.891 93.9982 231.705 92.4785 230.155 91.4056C228.605 90.3336 226.765 89.7588 224.88 89.7588H53.8328C51.3781 89.7645 49.0258 90.7436 47.2921 92.4818C45.5583 94.2192 44.5847 96.5737 44.5847 99.0285C44.5876 99.9709 44.7306 100.907 45.0089 101.807L84.3133 224.832C84.9542 226.822 86.2517 228.536 87.9936 229.692C89.7353 230.848 91.8182 231.378 93.9005 231.196L266.306 216.56C268.756 216.359 271.025 215.195 272.617 213.322C274.207 211.448 274.989 209.02 274.79 206.57C274.691 205.74 274.484 204.927 274.175 204.152Z"
          fill="#AD5BE7"
        />
        <path
          d="M49.5905 90.968L88.9584 214.057C89.61 216.044 90.9131 217.754 92.6571 218.909C94.401 220.063 96.4832 220.596 98.567 220.42L150.195 216.051L270.866 205.742C272.079 205.648 273.262 205.316 274.346 204.763C275.429 204.211 276.394 203.45 277.182 202.523C277.971 201.597 278.568 200.523 278.941 199.364C279.312 198.206 279.452 196.985 279.351 195.773C279.264 194.902 279.065 194.046 278.756 193.228L269.105 167.774L251.649 121.109L238.116 85.0499C237.451 83.2865 236.265 81.7668 234.715 80.6947C233.166 79.6218 231.325 79.0479 229.441 79.0479H58.3931C57.1786 79.0479 55.976 79.2867 54.854 79.7514C53.732 80.2161 52.7125 80.8977 51.8537 81.7562C50.995 82.6147 50.3137 83.6346 49.849 84.7564C49.3842 85.8782 49.145 87.0807 49.145 88.2955C49.1577 89.2037 49.3078 90.1046 49.5905 90.968Z"
          fill="white"
        />
        <path
          opacity="0.18"
          d="M196.289 146.936C198.419 146.804 199.85 144.695 199.185 142.667L192.269 121.555C191.812 120.161 190.48 119.243 189.014 119.313L138.776 121.727C136.603 121.832 135.139 123.995 135.851 126.052L143.391 147.833C143.869 149.217 145.213 150.112 146.674 150.021L196.289 146.936Z"
          fill="#AD5BE7"
        />
        <path
          opacity="0.18"
          d="M210.149 186.069C212.279 185.937 213.71 183.828 213.045 181.8L206.129 160.688C205.672 159.294 204.34 158.376 202.874 158.446L152.636 160.86C150.462 160.965 148.999 163.128 149.711 165.184L157.25 186.966C157.729 188.35 159.073 189.245 160.534 189.153L210.149 186.069Z"
          fill="#AD5BE7"
        />
        <path
          opacity="0.18"
          d="M216.778 100.725L229.78 143.848C229.972 144.542 230.389 145.154 230.964 145.587C231.539 146.021 232.242 146.252 232.962 146.245L265.214 145.314C266.332 145.282 267.087 144.159 266.694 143.112L250.388 99.5767C250.162 98.9725 249.601 98.5592 248.957 98.5209L219.684 96.8015C219.203 96.7811 218.724 96.8789 218.289 97.0868C217.854 97.2947 217.477 97.6053 217.19 97.9926C216.903 98.3798 216.716 98.8315 216.644 99.3076C216.572 99.7846 216.619 100.271 216.778 100.725Z"
          fill="#AD5BE7"
        />
        <path
          d="M295.917 133.242L282.469 95.1682C282.263 94.5567 281.881 94.0195 281.373 93.6241C280.863 93.2279 280.249 92.9906 279.606 92.9409L222.93 89.5893C222.447 89.5746 221.968 89.6765 221.533 89.8869C221.099 90.0972 220.722 90.4094 220.434 90.7967C220.146 91.1848 219.957 91.6356 219.882 92.1126C219.806 92.5895 219.848 93.077 220.002 93.5344L233.005 136.679C233.199 137.373 233.615 137.986 234.189 138.422C234.763 138.859 235.464 139.095 236.186 139.097L293.329 137.378C293.806 137.35 294.269 137.209 294.68 136.966C295.092 136.722 295.438 136.384 295.692 135.979C295.946 135.574 296.098 135.114 296.137 134.638C296.176 134.162 296.1 133.683 295.917 133.242Z"
          fill="white"
        />
        <path
          d="M258.672 127.166C259.061 128.177 259.256 128.682 259.673 128.969C260.09 129.255 260.632 129.255 261.715 129.255H261.972C264.131 129.255 265.211 129.255 265.696 128.546C266.182 127.836 265.792 126.829 265.013 124.816L262.808 119.12C262.417 118.112 262.222 117.608 261.806 117.322C261.389 117.037 260.848 117.037 259.767 117.037H259.52C257.363 117.037 256.285 117.037 255.799 117.745C255.314 118.452 255.702 119.458 256.477 121.471L258.672 127.166Z"
          fill="#AD5BE7"
        />
        <path
          d="M249.74 127.17C250.13 128.179 250.325 128.683 250.742 128.969C251.158 129.255 251.7 129.255 252.782 129.255H253.043C255.201 129.255 256.281 129.255 256.767 128.545C257.253 127.837 256.863 126.83 256.084 124.816L248.383 104.91C247.993 103.901 247.798 103.397 247.381 103.111C246.965 102.825 246.423 102.825 245.341 102.825H245.081C242.922 102.825 241.842 102.825 241.356 103.534C240.87 104.243 241.26 105.249 242.039 107.263L249.74 127.17Z"
          fill="#AD5BE7"
        />
        <path
          d="M267.622 127.17C268.012 128.179 268.207 128.683 268.624 128.969C269.04 129.255 269.581 129.255 270.663 129.255H270.924C273.083 129.255 274.162 129.255 274.648 128.546C275.134 127.837 274.744 126.83 273.966 124.816L268.726 111.273C268.335 110.264 268.14 109.76 267.723 109.474C267.306 109.188 266.766 109.188 265.684 109.188H265.423C263.264 109.188 262.185 109.188 261.699 109.898C261.213 110.606 261.603 111.613 262.381 113.626L267.622 127.17Z"
          fill="#48106F"
        />
        <path
          d="M240.789 127.168C241.179 128.178 241.374 128.683 241.791 128.968C242.208 129.254 242.749 129.254 243.831 129.254H244.093C246.251 129.254 247.33 129.254 247.816 128.546C248.302 127.837 247.913 126.831 247.135 124.818L236.589 97.5288C236.199 96.5195 236.004 96.0149 235.588 95.7295C235.171 95.4434 234.63 95.4434 233.548 95.4434H233.285C231.127 95.4434 230.049 95.4434 229.563 96.1518C229.077 96.8603 229.466 97.8672 230.243 99.8801L240.789 127.168Z"
          fill="#AD5BE7"
        />
        <path
          d="M74.1111 117.545C73.2633 114.404 73.6831 111.056 75.28 108.221C76.877 105.386 79.5234 103.293 82.6492 102.39C85.7751 101.488 89.1302 101.849 91.9922 103.396C94.8546 104.944 96.9939 107.553 97.951 110.663C98.9073 113.772 98.6049 117.134 97.108 120.022C95.6104 122.911 93.039 125.096 89.9465 126.107C86.854 127.118 83.4883 126.873 80.574 125.427C77.6598 123.98 75.4303 121.447 74.3656 118.373L74.2384 117.969L74.1111 117.545Z"
          fill="#EFE0FA"
        />
        <path
          d="M101.242 196.303C100.603 193.224 101.151 190.016 102.777 187.324C104.403 184.632 106.985 182.654 110.008 181.784C113.031 180.915 116.27 181.22 119.077 182.638C121.884 184.056 124.052 186.482 125.146 189.431V189.834C125.146 189.961 125.146 190.109 125.295 190.236C126.134 193.257 125.804 196.484 124.369 199.271C122.935 202.059 120.5 204.202 117.554 205.273C114.607 206.345 111.366 206.266 108.475 205.051C105.585 203.836 103.259 201.575 101.962 198.721C101.835 198.318 101.729 197.915 101.602 197.512C101.475 197.109 101.39 196.706 101.242 196.303Z"
          fill="#EFE0FA"
        />
        <path
          d="M88.5569 152.715C88.5569 149.412 89.8687 146.245 92.2034 143.91C94.5384 141.575 97.7057 140.264 101.008 140.264C104.31 140.264 107.477 141.575 109.812 143.91C112.147 146.245 113.458 149.412 113.458 152.715V153.584C113.458 156.886 112.147 160.053 109.812 162.388C107.477 164.724 104.31 166.035 101.008 166.035C97.7057 166.035 94.5384 164.724 92.2034 162.388C89.8687 160.053 88.5569 156.886 88.5569 153.584V152.715Z"
          fill="#EFE0FA"
        />
        <path
          d="M86.4988 114.131L83.5717 104.735C84.3982 104.48 85.2533 104.331 86.117 104.289C88.2771 104.208 90.4041 104.839 92.1712 106.084C93.9379 107.33 95.2464 109.121 95.8953 111.183C96.1497 112.01 96.2997 112.864 96.3405 113.728C96.3674 114.59 96.2891 115.452 96.1073 116.295C95.7331 117.979 94.9121 119.532 93.7316 120.792C92.5682 122.072 91.0837 123.018 89.4317 123.533C87.7797 124.048 86.0203 124.112 84.3353 123.719C83.5049 123.525 82.7003 123.233 81.9384 122.849C81.1725 122.444 80.46 121.946 79.8173 121.364C78.5648 120.205 77.6301 118.745 77.1023 117.122L86.4988 114.131Z"
          fill="#AD5BE7"
        />
        <path
          d="M100.838 153.117V143.275C102.56 143.288 104.248 143.749 105.738 144.612C106.516 145.031 107.23 145.56 107.859 146.181C109.086 147.399 109.969 148.92 110.419 150.59C110.868 152.26 110.867 154.019 110.417 155.689C109.967 157.358 109.084 158.879 107.855 160.096C106.627 161.313 105.099 162.184 103.426 162.62C101.761 163.087 99.9997 163.087 98.3349 162.62C97.505 162.406 96.7068 162.085 95.9592 161.666L100.838 153.117Z"
          fill="#AD5BE7"
        />
        <path
          d="M113.416 193.673L110.362 184.318C112.007 183.794 113.76 183.698 115.453 184.043C117.574 184.493 119.487 185.631 120.895 187.281C122.302 188.93 123.126 190.998 123.237 193.164C123.283 194.017 123.212 194.874 123.025 195.709C122.851 196.549 122.566 197.362 122.177 198.127C121.605 199.274 120.811 200.297 119.843 201.139C118.875 201.979 117.751 202.621 116.534 203.027L113.48 193.651L113.416 193.673Z"
          fill="#AD5BE7"
        />
        <path d="M133.737 53L111.995 63.0541L115.664 75.8657L133.737 53Z" fill="#AD5BE7" />
        <path d="M133.735 53H99.8608L110.17 59.2997L133.735 53Z" fill="#AD5BE7" />
        <path d="M106.246 68.7175L111.994 63.0541L133.736 53L106.246 68.7175Z" fill="#AD5BE7" />
        <path d="M106.246 68.7175L111.994 63.0541L133.736 53L110.171 59.2997L106.246 68.7175Z" fill="#791BBB" />
        <path
          d="M286.341 159.017V178.703C286.768 179.43 286.61 180.36 285.967 180.904C285.322 181.449 284.381 181.449 283.736 180.904C283.093 180.36 282.935 179.43 283.362 178.703V159.017C283.362 151.879 277.587 146.091 270.462 146.091C269.945 146.095 269.438 146.091 268.936 146.091C268.408 146.091 267.891 146.095 267.378 146.091C260.253 146.091 254.478 151.879 254.478 159.017V178.703C254.898 179.43 254.736 180.355 254.094 180.896C253.451 181.436 252.514 181.436 251.871 180.896C251.228 180.355 251.065 179.43 251.485 178.703V159.017C250.919 152.424 254.108 146.074 259.731 142.604C265.353 139.132 272.449 139.132 278.07 142.604C283.693 146.074 286.883 152.424 286.315 159.017H286.341Z"
          fill="#AD5BE7"
        />
        <path
          d="M292.53 176H240.47C234.135 176 229 180.588 229 186.247V232.753C229 238.412 234.135 243 240.47 243H292.53C298.865 243 304 238.412 304 232.753V186.247C304 180.588 298.865 176 292.53 176Z"
          fill="#48106F"
        />
        <path
          d="M292.541 176H253.459C247.131 176 242 180.583 242 186.237V232.763C242 238.417 247.131 243 253.459 243H292.541C298.869 243 304 238.417 304 232.763V186.237C304 180.583 298.869 176 292.541 176Z"
          fill="#791BBB"
        />
        <path
          d="M270.937 211.427C271.642 208.944 269.752 206.195 269.301 203.651C269.22 203.199 269.211 202.726 269.286 202.25C269.608 200.171 271.393 198.638 273.492 198.638C275.592 198.638 277.377 200.171 277.699 202.25C277.771 202.713 277.765 203.174 277.689 203.616C277.259 206.161 275.408 208.962 276.183 211.424L276.742 213.206C277.415 215.341 275.823 217.516 273.588 217.516C271.393 217.516 269.807 215.411 270.406 213.296L270.937 211.427Z"
          fill="#F7F0FD"
        />
      </svg>
    </Box>
  );
}

export default memo(PageLockIllustration);
