import useAlertBar from '@/hooks/useAlertBar';
import { Box, Container, styled } from '@mui/material';
import { Outlet } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const OverrideWrapperLogo = styled(Box, {
  shouldForwardProp: (props) => props !== 'showAlert' || props !== 'alertHeight',
})(({ theme, showAlert, alertHeight }) => {
  return {
    paddingTop: showAlert ? theme.mixins.rem(alertHeight + 40) : 'unset',
  };
});

const UploadLayout = () => {
  const { showAlert, height } = useAlertBar();

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <Container>
        <OverrideWrapperLogo py={3} showAlert={showAlert} alertHeight={height}>
          <RouterLink to="/">
            <img alt="logo" src={'/logo/athena-business-grey.svg'} height={30} />
          </RouterLink>
        </OverrideWrapperLogo>
        <Outlet />
      </Container>
    </Box>
  );
};
export default UploadLayout;
