import { role } from '@/enum/roleAndPermission';
import { userRoleEnum } from '@/enum/user';
import { userStatus as userStatusEnum } from '@/enum/userStatus';
import { parseSessionStatus } from '@/utils/session';
import { sortWith } from '@/utils/sort';
import { isEmpty } from 'lodash';
import dayjs, { getMinutesDuration, toDayMonthDateYearTime } from 'utils/date';

export const transformUserRole = (roles = []) => {
  const hasAdminRole = roles.includes(userRoleEnum.admin);
  const hasUserRole = roles.includes(userRoleEnum.mentee) && roles.includes(userRoleEnum.mentor);
  const result = [];

  if (hasAdminRole) {
    result.push('Admin');
  }
  if (hasUserRole) {
    result.push('User');
  }

  return result.join(', ');
};

export const findActiveTab = (pathname, defaultTab, tabPaths = {}, tabValue = {}) => {
  const active = Object.keys(tabPaths).find((path) => pathname.includes(tabPaths[path]));

  return active ? tabValue[active] : defaultTab;
};

export const parserUserDetail = (user) => ({
  employeeCode: user.EmployeeCode,
  firstName: user.FirstName,
  lastName: user.LastName,
  displayName: user.DisplayName,
  fullName: `${user.FirstName} ${user.LastName}`.trim(),
  email: user.Email,
  imgUrl: user.ImgUrl,
  phoneNumber: user.PhoneNumber,
  about: user.About,
  isGuest: user.IsGuest,
  organization: user.IsGuest ? user.GuestOrganization : user.Organization,
  jobLevel: user.JobLevel,
  jobTitle: user.JobTitle,
  businessUnit: user.BusinessUnit,
  department: user.Department,
  team: user.Team,
  role: user.Role,
  roleEnum: user.RoleEnum,
  status: user.UserStatus,
  displayRole: transformUserRole(user.Role),
  suspendedBy: user.SuspendedBy,
  lastUpdated: user.UpdatedAt,
});

export const parserUserList = (userArr) =>
  userArr.map(
    ({
      AreaOfExpertise,
      BusinessUnit,
      Department,
      EmployeeCode,
      FirstName,
      JobLevel,
      JobTitle,
      LastLoginAt,
      LastName,
      Team,
      ID,
      Email,
      Status,
      ImgUrl,
      IsContributor,
      IsProfileComplete,
      DisplayName,
      PhoneNumber,
      IsGuest,
      GuestOrganization,
      Organization,
    }) => ({
      id: ID,
      areaOfExpertise: AreaOfExpertise || [],
      businessUnit: IsGuest ? '-' : BusinessUnit,
      department: IsGuest ? '-' : Department,
      employeeId: EmployeeCode,
      employeeCode: EmployeeCode,
      firstName: FirstName,
      jobLevel: JobLevel,
      jobTitle: JobTitle,
      lastActive: toDayMonthDateYearTime(LastLoginAt),
      lastName: LastName,
      team: IsGuest ? '-' : Team,
      email: Email,
      status: Status,
      imgUrl: ImgUrl,
      isContributor: IsContributor,
      isProfileComplete: IsProfileComplete,
      displayName: DisplayName,
      phoneNumber: PhoneNumber,
      isGuest: IsGuest,
      organization: IsGuest ? GuestOrganization : Organization,
      isSuspended: Status === userStatusEnum?.suspended,
    })
  );

export const transformMentorExperienceItem = (experience = {}) => ({
  company: experience.Company,
  position: experience.Position,
  description: experience.Description,
  startDate: experience.StartDatetime,
  endDate: experience.EndDatetime || null,
  presentWorkHere: !experience.EndDatetime,
});

export const getSortedMentorExperienceList = (experiences = []) => {
  const sortedStartDateMentorExperienceList = sortWith({
    sortFrom: 'startDate',
    sortBy: 'desc',
    propType: 'date',
  })(experiences);

  const presentMentorExperienceList = sortedStartDateMentorExperienceList.filter((item) => !item.endDate);
  const otherMentorExperienceList = sortedStartDateMentorExperienceList.filter((item) => !!item.endDate);
  const sortedMentorExperienceList = [...presentMentorExperienceList, ...otherMentorExperienceList];

  return sortedMentorExperienceList;
};

export const transformUserSessionItem = (session) => {
  const start = dayjs(session.StartDateTime);
  const end = dayjs(session.EndDateTime);
  const fullName = `${session.User.FirstName} ${session.User.LastName}`;

  return {
    sessionId: session.Id,
    topic: session.Topic,
    fullName,
    user: {
      id: session.User.Id,
      firstName: session.User.FirstName,
      imgUrl: session.User.ImgUrl,
      lastName: session.User.LastName,
    },
    duration: getMinutesDuration(start, end),
    bookingStatus: parseSessionStatus({ status: session.Status, startDateTime: session.StartDateTime }),
    startDateTime: session.StartDateTime,
    endDateTime: session.EndDateTime,
    requestDateTime: session.RequestedDateTime,
  };
};

export const transformSearchUserDisplay = (userList = []) => {
  return userList.map((user) => {
    const { Id, Email, FirstName, LastName, ImgUrl, DisplayName, RoleName, IsGuest } = user;
    const fullName = `${FirstName} ${LastName}`;
    return {
      id: Id,
      email: Email,
      fullName,
      displayName: DisplayName,
      imgUrl: ImgUrl,
      roleName: RoleName,
      isGuest: IsGuest,
    };
  });
};

export const canEditUser = ({ isLoadingUser, canEditPermission, adminUserRole, userDetail }) => {
  const { SUPER_ADMIN, ADMIN } = role;
  const userStatus = userDetail?.status;
  const userRole = userDetail?.roleEnum;
  const isGuest = userDetail?.isGuest;
  const isSuspended = userStatus === userStatusEnum?.suspended;

  if (isLoadingUser || !canEditPermission || isEmpty(userDetail)) {
    return false;
  }

  // Cannot edit information of a suspended user
  if (isSuspended) {
    return false;
  }

  // when has permission will able to edit guest information
  if (isGuest) {
    return true;
  }

  // Super Admin is all access
  if (adminUserRole === SUPER_ADMIN) {
    return true;
  }

  // Admin can’t edit Super admin’s user information
  if (adminUserRole === ADMIN) {
    if (userRole === SUPER_ADMIN) {
      return false;
    }

    return true;
  }

  // default
  return false;
};

export const transformUserWorkspace = ({ Name, LogoUrl, ID, Member }) => {
  return {
    name: Name,
    logoUrl: LogoUrl,
    id: ID,
    member: Member,
  };
};

export const canSuspendUser = ({ isLoadingUser, canSuspendPermission, adminUserRole, adminId, userId, userDetail }) => {
  const { SUPER_ADMIN, ADMIN } = role;
  const userStatus = userDetail?.status;
  const userRole = userDetail?.roleEnum;
  const isSuspended = userStatus === userStatusEnum?.suspended;

  if (isLoadingUser || !canSuspendPermission || isEmpty(userDetail)) {
    return false;
  }

  // Cannot suspended user
  if (isSuspended) {
    return false;
  }

  // Cannot suspended yourself
  if (adminId === userId) {
    return false;
  }

  // Super Admin is all access
  if (adminUserRole === SUPER_ADMIN) {
    return true;
  }

  // Admin can’t suspend Super admin’s account
  if (adminUserRole === ADMIN) {
    if (userRole === SUPER_ADMIN) {
      return false;
    }

    return true;
  }

  // default
  return false;
};
