import { permissionEnum } from '@/enum/permission';

const {
  BO_USER_INVITE_VIEW,
  BO_USER_INVITE_ADD,

  BO_DOMAIN_EXPERTISE_VIEW,
  BO_DOMAIN_EXPERTISE_ADD,
  BO_DOMAIN_EXPERTISE_EDIT,
  BO_DOMAIN_EXPERTISE_DELETE,

  BO_EXPERTISE_VIEW,
  BO_EXPERTISE_ADD,
  BO_EXPERTISE_EDIT,
  BO_EXPERTISE_DELETE,

  BO_BUSINESS_UNIT_VIEW,
  BO_BUSINESS_UNIT_ADD,
  BO_BUSINESS_UNIT_EDIT,
  BO_BUSINESS_UNIT_DELETE,

  BO_DEPARTMENT_VIEW,
  BO_DEPARTMENT_ADD,
  BO_DEPARTMENT_EDIT,
  BO_DEPARTMENT_DELETE,

  BO_TEAM_VIEW,
  BO_TEAM_ADD,
  BO_TEAM_EDIT,
  BO_TEAM_DELETE,

  BO_JOB_LEVEL_VIEW,
  BO_JOB_LEVEL_ADD,
  BO_JOB_LEVEL_EDIT,
  BO_JOB_LEVEL_DELETE,

  BO_ROLE_SETTING_VIEW,
  BO_ROLE_SETTING_ADD_SUPER_ADMIN,
  BO_ROLE_SETTING_ADD_ADMIN,
  BO_ROLE_SETTING_ADD_ANALYST,
  BO_ROLE_SETTING_EDIT_SUPER_ADMIN,
  BO_ROLE_SETTING_EDIT_ADMIN,
  BO_ROLE_SETTING_EDIT_ANALYST,
  BO_ROLE_SETTING_DELETE_SUPER_ADMIN,
  BO_ROLE_SETTING_DELETE_ADMIN,
  BO_ROLE_SETTING_DELETE_ANALYST,

  BO_USER_ONBOARD_EDIT,
  BO_USER_SUSPEND,
} = permissionEnum;

export const permissionAccess = (permissions = []) => ({
  userInvite: {
    view: permissions.includes(BO_USER_INVITE_VIEW),
    add: permissions.includes(BO_USER_INVITE_ADD),
  },
  userOnboarded: {
    edit: permissions.includes(BO_USER_ONBOARD_EDIT),
    suspend: permissions.includes(BO_USER_SUSPEND),
  },
  domainExpertise: {
    view: permissions.includes(BO_DOMAIN_EXPERTISE_VIEW),
    add: permissions.includes(BO_DOMAIN_EXPERTISE_ADD),
    edit: permissions.includes(BO_DOMAIN_EXPERTISE_EDIT),
    delete: permissions.includes(BO_DOMAIN_EXPERTISE_DELETE),
  },
  expertise: {
    view: permissions.includes(BO_EXPERTISE_VIEW),
    add: permissions.includes(BO_EXPERTISE_ADD),
    edit: permissions.includes(BO_EXPERTISE_EDIT),
    delete: permissions.includes(BO_EXPERTISE_DELETE),
  },
  businessUnit: {
    view: permissions.includes(BO_BUSINESS_UNIT_VIEW),
    add: permissions.includes(BO_BUSINESS_UNIT_ADD),
    edit: permissions.includes(BO_BUSINESS_UNIT_EDIT),
    delete: permissions.includes(BO_BUSINESS_UNIT_DELETE),
  },
  department: {
    view: permissions.includes(BO_DEPARTMENT_VIEW),
    add: permissions.includes(BO_DEPARTMENT_ADD),
    edit: permissions.includes(BO_DEPARTMENT_EDIT),
    delete: permissions.includes(BO_DEPARTMENT_DELETE),
  },
  team: {
    view: permissions.includes(BO_TEAM_VIEW),
    add: permissions.includes(BO_TEAM_ADD),
    edit: permissions.includes(BO_TEAM_EDIT),
    delete: permissions.includes(BO_TEAM_DELETE),
  },
  jobLevel: {
    view: permissions.includes(BO_JOB_LEVEL_VIEW),
    add: permissions.includes(BO_JOB_LEVEL_ADD),
    edit: permissions.includes(BO_JOB_LEVEL_EDIT),
    delete: permissions.includes(BO_JOB_LEVEL_DELETE),
  },
  roleAndPermission: {
    view: permissions.includes(BO_ROLE_SETTING_VIEW),
    add: {
      superAdmin: permissions.includes(BO_ROLE_SETTING_ADD_SUPER_ADMIN),
      admin: permissions.includes(BO_ROLE_SETTING_ADD_ADMIN),
      analyst: permissions.includes(BO_ROLE_SETTING_ADD_ANALYST),
    },
    edit: {
      superAdmin: permissions.includes(BO_ROLE_SETTING_EDIT_SUPER_ADMIN),
      admin: permissions.includes(BO_ROLE_SETTING_EDIT_ADMIN),
      analyst: permissions.includes(BO_ROLE_SETTING_EDIT_ANALYST),
    },
    delete: {
      superAdmin: permissions.includes(BO_ROLE_SETTING_DELETE_SUPER_ADMIN),
      admin: permissions.includes(BO_ROLE_SETTING_DELETE_ADMIN),
      analyst: permissions.includes(BO_ROLE_SETTING_DELETE_ANALYST),
    },
  },
});
