import LoadingScreen from '@/components/template/LoadingScreen';
import GuestGuard from '@/guards/GuestGuard';
import DashboardLayout from '@/layouts';
import navConfig from '@/layouts/navbar/NavConfig';
import { lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import('../../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../../pages/auth/Register')));
const RegisterSuccess = Loadable(lazy(() => import('../../pages/auth/RegisterSuccess')));
const ResetPassword = Loadable(lazy(() => import('../../pages/auth/ResetPassword')));

export default {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register/success',
      element: (
        <GuestGuard>
          <DashboardLayout showSidebar={false} navConfig={navConfig} />
        </GuestGuard>
      ),
      children: [
        {
          path: '',
          element: <RegisterSuccess />,
        },
      ],
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
  ],
};
