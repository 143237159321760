import HeaderBreadcrumbs from '@/components/molecules/HeaderBreadcrumbs';
import { Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import Page from './Page';

const AdminPage = forwardRef(
  ({ pageTitle, pageSubtitle, info, title = '', breadcrumbs, breadcrumbsAction, children, noTitle }, ref) => {
    return (
      <Page title={title} subTitle={pageSubtitle} ref={ref}>
        <Container maxWidth="xl">
          <HeaderBreadcrumbs
            heading={pageTitle}
            subHeader={pageSubtitle}
            info={info}
            links={breadcrumbs}
            action={breadcrumbsAction}
            noTitle={noTitle}
          />
          <Box sx={{ mt: 5 }}>{children}</Box>
        </Container>
      </Page>
    );
  }
);

AdminPage.propTypes = {
  children: PropTypes.node.isRequired,
  pageSubtitle: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.node,
  breadcrumbsAction: PropTypes.element,
  pageTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  breadcrumbs: PropTypes.array,
  noTitle: PropTypes.bool,
};

export default AdminPage;
