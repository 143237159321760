import { useTheme } from '@emotion/react';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import PropTypes, { number, string } from 'prop-types';
import { memo } from 'react';
import MultipleWorkspaceSelect from './MultipleWorkspaceSelect';

const MultipleWorkspaceForm = ({ onChangeWorkspace = () => {}, value = 0, workspaceList = [], isWorkspaceLoading }) => {
  const theme = useTheme();
  return (
    <Box p={2} bgcolor={theme.palette.background.secondNeutral} borderRadius={1}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="overline" fontWeight="bold">
            My Back office
          </Typography>
          <Typography variant="caption" color="text.secondary">
            You can switch your back office here.
          </Typography>
        </Stack>
        {isWorkspaceLoading ? (
          <Skeleton height={43} />
        ) : (
          <MultipleWorkspaceSelect onChangeWorkspace={onChangeWorkspace} value={value} workspaceList={workspaceList} />
        )}
      </Stack>
    </Box>
  );
};

MultipleWorkspaceForm.propTypes = {
  onChangeWorkspace: PropTypes.func,
  value: PropTypes.oneOfType([number, string]),
  workspaceList: PropTypes.array,
  isWorkspaceLoading: PropTypes.bool,
};

export default memo(MultipleWorkspaceForm);
