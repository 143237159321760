import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  isOpen: false,
  title: '',
  message: '',
};

const textMessage = {
  demote: {
    title: 'You don’t have permission on this.',
    message: 'Please re-log in to see & manage properties based on your role.',
  },
  remove: {
    title: 'You’ve been removed access from Back Office.',
    message:
      'You no longer have access to Back Office. To use Back Office functions again, you must be granted access.',
  },
};

const slice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    onOpenModal(state, { payload }) {
      state.isOpen = true;
      state.title = textMessage[payload.type].title;
      state.message = textMessage[payload.type].message;
    },

    onCloseModal(state) {
      state.isOpen = false;
      state.title = '';
      state.message = '';
    },
  },
});

export default slice.reducer;

export function onOpenModalPermission(type) {
  return () => {
    dispatch(slice.actions.onOpenModal({ type }));
  };
}

export function onCloseModalPermission() {
  return () => {
    dispatch(
      slice.actions.onCloseModal({
        isOpen: false,
      })
    );
  };
}

export const { onOpenModal, onCloseModal } = slice.actions;
