import { sessionStorageEnum } from '@/enum/sessionStorage';
import { fetchWorkspaceList } from '@/services/API/workspace';
import { transformUserWorkspace } from '@/transform/user';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useWorkspaceContext from '../useWorkspaceContext';

const useMultipleWorkspace = () => {
  const [isWorkspaceLoading, setWorkspaceLoading] = useState(false);
  const [workspaceList, setWorkspaceList] = useState([]);
  const [activeWorkspaceId, setActiveWorkspaceId] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { changeWorkspace } = useWorkspaceContext();

  const getWorkSpaceList = useCallback(async () => {
    const res = await fetchWorkspaceList();
    return res.data;
  }, []);

  const handleSelectChangeWorkspace = useCallback(
    async (event) => {
      const workspaceId = event.id;
      sessionStorage.setItem(sessionStorageEnum.activeWorkspaceId, workspaceId);
      setActiveWorkspaceId(workspaceId);
      navigate('/');
      changeWorkspace(workspaceId);
    },
    [changeWorkspace, navigate]
  );

  const getActiveWorkspace = useCallback(() => {
    return sessionStorage?.getItem(sessionStorageEnum.activeWorkspaceId);
  }, []);

  useEffect(() => {
    async function fetchWorkspace() {
      try {
        setWorkspaceLoading(true);
        const data = await getWorkSpaceList();

        const workspaceIds = data.map(({ ID }) => ID);
        const currentActiveWorkspaceId = sessionStorage.getItem(sessionStorageEnum.activeWorkspaceId);
        const validWorkspace = workspaceIds.includes(currentActiveWorkspaceId);

        if (validWorkspace) {
          setActiveWorkspaceId(currentActiveWorkspaceId);
        } else {
          // NOTE: set default workspace to first workspace
          const defaultActiveWorkspaceId = workspaceIds[0];

          await sessionStorage.removeItem(sessionStorageEnum.activeWorkspaceId);
          await sessionStorage.setItem(sessionStorageEnum.activeWorkspaceId, defaultActiveWorkspaceId);
          setActiveWorkspaceId(defaultActiveWorkspaceId);
        }

        setWorkspaceList(data.map(transformUserWorkspace));
      } catch (error) {
        console.error(error);
        const errorMessage = error.response?.data?.errorMessage;
        setWorkspaceList([]);
        setActiveWorkspaceId('');
        await sessionStorage.removeItem(sessionStorageEnum.activeWorkspaceId);
        enqueueSnackbar(errorMessage ?? 'Something went wrong, please try again.', { variant: 'error' });
      } finally {
        setWorkspaceLoading(false);
      }
    }
    fetchWorkspace();
  }, [enqueueSnackbar, getWorkSpaceList]);

  return {
    handleSelectChangeWorkspace,
    getActiveWorkspace,
    activeWorkspaceId,
    isWorkspaceLoading,
    workspaceList,
  };
};
export default useMultipleWorkspace;
