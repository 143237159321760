export const userRoleEnum = Object.freeze({
  admin: 'ADMIN',
  mentee: 'MENTEE',
  mentor: 'MENTOR',
});

export const userSessionSearchOption = (isContributor) =>
  Object.freeze([
    {
      value: 'topic',
      label: 'Topic',
    },
    {
      value: 'fullName',
      label: isContributor ? 'User' : 'Contributor',
    },
  ]);
