export const initialStatePermission = {
  permission: [],
  initialize: false,
};

export const reducerPermission = (state, action) => {
  if (action.type === 'INITIALIZE') {
    const { permission } = action.payload;
    return {
      ...state,
      permission,
      initialize: true,
    };
  }

  return state;
};
