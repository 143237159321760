import success from '@/routes/routes-config/success';
import { Navigate, useRoutes } from 'react-router-dom';
import auth from 'routes/routes-config/auth';
import main from 'routes/routes-config/main';
import upload from 'routes/routes-config/upload';
import util from 'routes/routes-config/util';

export default function Router() {
  return useRoutes([auth, main, upload, success, util, { path: '*', element: <Navigate to="/404" replace /> }]);
}
