import { Suspense, lazy } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import LogoOnlyLayout from '@/layouts/LogoOnlyLayout';
import LoadingScreen from '@/components/template/LoadingScreen';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const ComingSoon = Loadable(lazy(() => import('../../pages/CommingSoon')));
const Maintenance = Loadable(lazy(() => import('../../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../../pages/Page404')));

export default {
  path: '*',
  element: <LogoOnlyLayout />,
  children: [
    { path: 'coming-soon', element: <ComingSoon /> },
    { path: 'maintenance', element: <Maintenance /> },
    { path: '500', element: <Page500 /> },
    { path: '404', element: <Page404 /> },
    { path: '403', element: <Page403 /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ],
};
